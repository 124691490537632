:root {
  --ck-image-style-spacing: 1.5em;
  --ck-todo-list-checkmark-size: 16px;
  --ck-sample-base-spacing: 2em;
  --ck-sample-color-white: #fff;
  --ck-sample-color-green: #279863;
  --ck-sample-color-blue: #1a9aef;
  --ck-sample-container-width: 1285px;
  --ck-sample-sidebar-width: 350px;
  --ck-sample-editor-min-height: 400px;
  --ck-sample-editor-z-index: 10;
}

.editor-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  justify-content: center;
}

.ck-media__wrapper {
  width: 70%;
}

.ck-editor,
.ck-editor__editable {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.ck-content {
  word-break: break-all;
  font-size: 16px;
  max-width: 100%;
}

/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
  height: fit-content;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
  width: 100%;
  height: fit-content;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
  display: block;
}

/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: 0.15em;
  border-radius: 2px;
}

/* ckeditor5-image/theme/image.css */
.ck-content .image {
  display: table;
  clear: both;
  text-align: center;
  margin: 1em auto;
}
.ck-content .image img {
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 100%;
  min-width: 100%;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side,
.ck-content .image-style-align-left,
.ck-content .image-style-align-center,
.ck-content .image-style-align-right {
  max-width: 50%;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
  position: relative;
  clear: both;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
  content: "";
  position: absolute;
  border-bottom: 2px dashed hsl(0, 0%, 77%);
  width: 100%;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
  position: relative;
  z-index: 1;
  padding: 0.3em 0.6em;
  display: block;
  text-transform: uppercase;
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: bold;
  color: hsl(0, 0%, 20%);
  background: #fff;
  box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
  border-left: 0;
  border-right: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
  clear: both;
  margin: 1em 0;
  display: block;
  min-width: 15em;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table {
  max-width: 100%;
  margin: 1em auto;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
  min-width: 2em;
  padding: 0.4em;
  border: 1px double hsl(0, 0%, 70%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table th {
  font-weight: bold;
  background: hsla(0, 0%, 0%, 5%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
  list-style: none;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
  margin-bottom: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
  margin-top: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
  -webkit-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  width: var(--ck-todo-list-checkmark-size);
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background,
    250ms ease-in-out border;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: "";
  left: calc(var(--ck-todo-list-checkmark-size) / 3);
  top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8)
    calc(var(--ck-todo-list-checkmark-size) / 8) 0;
  transform: rotate(45deg);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
  background: hsl(126, 64%, 41%);
  border-color: hsl(126, 64%, 41%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
  border-color: hsl(0, 0%, 100%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle;
}
/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: hsl(0, 0%, 20%);
  background-color: hsl(0, 0%, 97%);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
  background-color: var(--ck-highlight-marker-yellow);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
  background-color: var(--ck-highlight-marker-green);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
  background-color: var(--ck-highlight-marker-pink);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
  background-color: var(--ck-highlight-marker-blue);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}
/* ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
  border-width: 1px 0 0;
  border-style: solid;
  border-color: hsl(0, 0%, 37%);
  margin: 0;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
  padding: 1em;
  color: #353535;
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}
@media print {
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
    padding: 0;
  }
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
    display: none;
  }
}

.text_area {
  border-style: solid;
  border-width: 5px;
  width: 1600px;
}

.ck-editor__main {
  overflow: hidden;
  flex-grow: 1;
  line-height: 1;
}

.ck-editor__editable {
  font-size: 20px;
  min-height: 70vh;
  max-height: 70vh;
  height: 100%;
  box-sizing: border-box;
}

.ck-editor p {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.ck.ck-editor__editable_inline > :first-child {
  margin-top: 0;
}

/* PC */

.ck-content {
  color: #253d4e;
}

/* h1, h2, h3, h4, h5 */
.ck-content h1 {
  font-size: 48px !important;
  margin: 42px 0px;
}
.ck-content h2 {
  font-size: 42px !important;
  margin: 36px 0px;
}
.ck-content h3 {
  font-size: 32px !important;
  margin: 26px 0px;
}
.ck-content h4 {
  font-size: 26px !important;
  margin: 20px 0px;
}
.ck-content h5 {
  font-size: 22px !important;
  margin: 16px 0px;
}

.ck-content ul {
  padding: 0px 2em;
  margin: 1em 0px;
}
.ck-content li {
  list-style: disc;
  color: #253d4e;
}

.ck-content .text-huge {
  font-size: 48px;
}

.ck-content .text-big {
  font-size: 36px;
}

.ck-content .text-small {
  font-size: 19px;
}

.ck-content .text-tiny {
  font-size: 14px;
}

.ck-content :first-child {
  margin-top: 0px !important;
}

.ck-content p {
  margin-bottom: 0;
  padding-top: 0.15em;
  font-size: 16px;
  line-height: 1.2em;
  color: #253d4e;
}

.ck-content span {
  margin-bottom: 0;
  line-height: 1.4em;
  padding-bottom: 0.15em;
}

/* 환경뉴스, 블로그 에디터 전용 */
.board-editor .ck-content p {
  font-size: 16px;
}

/* 링크 */
.ck-content h1 a,
.ck-content h2 a,
.ck-content h3 a,
.ck-content h4 a,
.ck-content h5 a,
.ck-content p a,
.ck-content p a span {
  color: #3bb77e !important;
}

/* 테블릿 */
@media screen and (max-width: 991px) {
  .ck-content h1 {
    font-size: calc(48px - 0.24vw);
  }
  .ck-content h2 {
    font-size: calc(36px - 0.24vw);
  }
  .ck-content h3 {
    font-size: calc(24px - 0.24vw);
  }
  .ck-content .text-huge {
    font-size: 36px;
  }
  .ck-content .text-big {
    font-size: 26px;
  }
  .ck-content p {
    font-size: 16px;
  }
  .ck-content .text-small {
    font-size: 14px;
  }
  .ck-content .text-tiny {
    font-size: 11px;
  }
  figure.image {
    display: flex !important;
    flex-direction: column;
  }
  figure img {
    width: auto !important;
    height: auto !important;
  }
  figure.table {
    display: flex !important;
    width: auto !important;
  }
}

/* 모바일 */
@media screen and (max-width: 575px) {
  .ck-content {
    padding: 0px;
  }
  .ck-content h1 {
    font-size: calc(40px - 0.24vw) !important;
  }
  .ck-content h2 {
    font-size: calc(30px - 0.24vw) !important;
  }
  .ck-content h3 {
    font-size: calc(22px - 0.24vw) !important;
  }
  .ck-content h4 {
    font-size: calc(19px - 0.24vw) !important;
  }
  .ck-content h5 {
    font-size: calc(14px - 0.24vw) !important;
  }
  .ck-content .text-huge {
    font-size: 26px;
  }
  .ck-content .text-big {
    font-size: 20px;
  }
  .ck-content p {
    font-size: 14px;
  }
  .ck-content .text-small {
    font-size: 12px;
  }
  .ck-content .text-tiny {
    font-size: 10px;
  }
}
