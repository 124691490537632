@font-face {
  font-family: "uicons-regular-straight";
  src: url("../../fonts/uicons/uicons-regular-straight.eot")
      format("embedded-opentype"),
    url("../../fonts/uicons/uicons-regular-straight.woff2") format("woff2"),
    url("../../fonts/uicons/uicons-regular-straight.woff") format("woff");
}

i[class^="fi-rs-"]:before,
i[class*=" fi-rs-"]:before,
span[class^="fi-rs-"]:before,
span[class*="fi-rs-"]:before {
  font-family: uicons-regular-straight !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fi-rs-0:before {
  content: "\f101";
}
.fi-rs-1:before {
  content: "\f102";
}
.fi-rs-2:before {
  content: "\f103";
}
.fi-rs-3:before {
  content: "\f104";
}
.fi-rs-360-degrees:before {
  content: "\f105";
}
.fi-rs-4:before {
  content: "\f106";
}
.fi-rs-5:before {
  content: "\f107";
}
.fi-rs-6:before {
  content: "\f108";
}
.fi-rs-7:before {
  content: "\f109";
}
.fi-rs-8:before {
  content: "\f10a";
}
.fi-rs-9:before {
  content: "\f10b";
}
.fi-rs-a:before {
  content: "\f10c";
}
.fi-rs-acorn:before {
  content: "\f10d";
}
.fi-rs-ad:before {
  content: "\f10e";
}
.fi-rs-add-document:before {
  content: "\f10f";
}
.fi-rs-add-folder:before {
  content: "\f110";
}
.fi-rs-add:before {
  content: "\f111";
}
.fi-rs-address-book:before {
  content: "\f112";
}
.fi-rs-address-card:before {
  content: "\f113";
}
.fi-rs-air-conditioner:before {
  content: "\f114";
}
.fi-rs-air-freshener:before {
  content: "\f115";
}
.fi-rs-airplay:before {
  content: "\f116";
}
.fi-rs-alarm-clock:before {
  content: "\f117";
}
.fi-rs-alarm-exclamation:before {
  content: "\f118";
}
.fi-rs-alarm-plus:before {
  content: "\f119";
}
.fi-rs-alarm-snooze:before {
  content: "\f11a";
}
.fi-rs-album-circle-plus:before {
  content: "\f11b";
}
.fi-rs-album-circle-user:before {
  content: "\f11c";
}
.fi-rs-album-collection:before {
  content: "\f11d";
}
.fi-rs-album:before {
  content: "\f11e";
}
.fi-rs-alien:before {
  content: "\f11f";
}
.fi-rs-align-center:before {
  content: "\f120";
}
.fi-rs-align-justify:before {
  content: "\f121";
}
.fi-rs-align-left:before {
  content: "\f122";
}
.fi-rs-align-slash:before {
  content: "\f123";
}
.fi-rs-ambulance:before {
  content: "\f124";
}
.fi-rs-anchor:before {
  content: "\f125";
}
.fi-rs-angel:before {
  content: "\f126";
}
.fi-rs-angle-circle-down:before {
  content: "\f127";
}
.fi-rs-angle-circle-left:before {
  content: "\f128";
}
.fi-rs-angle-circle-right:before {
  content: "\f129";
}
.fi-rs-angle-circle-up:before {
  content: "\f12a";
}
.fi-rs-angle-double-left:before {
  content: "\f12b";
}
.fi-rs-angle-double-right:before {
  content: "\f12c";
}
.fi-rs-angle-double-small-down:before {
  content: "\f12d";
}
.fi-rs-angle-double-small-left:before {
  content: "\f12e";
}
.fi-rs-angle-double-small-right:before {
  content: "\f12f";
}
.fi-rs-angle-double-small-up:before {
  content: "\f130";
}
.fi-rs-angle-down:before {
  content: "\f131";
}
.fi-rs-angle-left:before {
  content: "\f132";
}
.fi-rs-angle-right:before {
  content: "\f133";
}
.fi-rs-angle-small-down:before {
  content: "\f134";
}
.fi-rs-angle-small-left:before {
  content: "\f135";
}
.fi-rs-angle-small-right:before {
  content: "\f136";
}
.fi-rs-angle-small-up:before {
  content: "\f137";
}
.fi-rs-angle-square-down:before {
  content: "\f138";
}
.fi-rs-angle-square-left:before {
  content: "\f139";
}
.fi-rs-angle-square-right:before {
  content: "\f13a";
}
.fi-rs-angle-square-up:before {
  content: "\f13b";
}
.fi-rs-angle-up:before {
  content: "\f13c";
}
.fi-rs-angry:before {
  content: "\f13d";
}
.fi-rs-animated-icon:before {
  content: "\f13e";
}
.fi-rs-apartment:before {
  content: "\f13f";
}
.fi-rs-aperture:before {
  content: "\f140";
}
.fi-rs-api:before {
  content: "\f141";
}
.fi-rs-apple-crate:before {
  content: "\f142";
}
.fi-rs-apple-whole:before {
  content: "\f143";
}
.fi-rs-apps-add:before {
  content: "\f144";
}
.fi-rs-apps-delete:before {
  content: "\f145";
}
.fi-rs-apps-sort:before {
  content: "\f146";
}
.fi-rs-apps:before {
  content: "\f147";
}
.fi-rs-archive:before {
  content: "\f148";
}
.fi-rs-archway:before {
  content: "\f149";
}
.fi-rs-arrow-alt-circle-down:before {
  content: "\f14a";
}
.fi-rs-arrow-alt-circle-left:before {
  content: "\f14b";
}
.fi-rs-arrow-alt-circle-right:before {
  content: "\f14c";
}
.fi-rs-arrow-alt-circle-up:before {
  content: "\f14d";
}
.fi-rs-arrow-alt-down:before {
  content: "\f14e";
}
.fi-rs-arrow-alt-from-bottom:before {
  content: "\f14f";
}
.fi-rs-arrow-alt-from-left:before {
  content: "\f150";
}
.fi-rs-arrow-alt-from-right:before {
  content: "\f151";
}
.fi-rs-arrow-alt-from-top:before {
  content: "\f152";
}
.fi-rs-arrow-alt-left:before {
  content: "\f153";
}
.fi-rs-arrow-alt-right:before {
  content: "\f154";
}
.fi-rs-arrow-alt-square-down:before {
  content: "\f155";
}
.fi-rs-arrow-alt-square-left:before {
  content: "\f156";
}
.fi-rs-arrow-alt-square-right:before {
  content: "\f157";
}
.fi-rs-arrow-alt-square-up:before {
  content: "\f158";
}
.fi-rs-arrow-alt-to-bottom:before {
  content: "\f159";
}
.fi-rs-arrow-alt-to-left:before {
  content: "\f15a";
}
.fi-rs-arrow-alt-to-right:before {
  content: "\f15b";
}
.fi-rs-arrow-alt-to-top:before {
  content: "\f15c";
}
.fi-rs-arrow-alt-up:before {
  content: "\f15d";
}
.fi-rs-arrow-circle-down:before {
  content: "\f15e";
}
.fi-rs-arrow-circle-left:before {
  content: "\f15f";
}
.fi-rs-arrow-circle-right:before {
  content: "\f160";
}
.fi-rs-arrow-circle-up:before {
  content: "\f161";
}
.fi-rs-arrow-down-from-dotted-line:before {
  content: "\f162";
}
.fi-rs-arrow-down-left:before {
  content: "\f163";
}
.fi-rs-arrow-down-small-big:before {
  content: "\f164";
}
.fi-rs-arrow-down-to-dotted-line:before {
  content: "\f165";
}
.fi-rs-arrow-down-to-square:before {
  content: "\f166";
}
.fi-rs-arrow-down-triangle-square:before {
  content: "\f167";
}
.fi-rs-arrow-down:before {
  content: "\f168";
}
.fi-rs-arrow-from-bottom:before {
  content: "\f169";
}
.fi-rs-arrow-from-left:before {
  content: "\f16a";
}
.fi-rs-arrow-from-right:before {
  content: "\f16b";
}
.fi-rs-arrow-from-top:before {
  content: "\f16c";
}
.fi-rs-arrow-left-from-line:before {
  content: "\f16d";
}
.fi-rs-arrow-left:before {
  content: "\f16e";
}
.fi-rs-arrow-right-to-bracket:before {
  content: "\f16f";
}
.fi-rs-arrow-right:before {
  content: "\f170";
}
.fi-rs-arrow-small-down:before {
  content: "\f171";
}
.fi-rs-arrow-small-left:before {
  content: "\f172";
}
.fi-rs-arrow-small-right:before {
  content: "\f173";
}
.fi-rs-arrow-small-up:before {
  content: "\f174";
}
.fi-rs-arrow-square-down:before {
  content: "\f175";
}
.fi-rs-arrow-square-left:before {
  content: "\f176";
}
.fi-rs-arrow-square-right:before {
  content: "\f177";
}
.fi-rs-arrow-square-up:before {
  content: "\f178";
}
.fi-rs-arrow-to-bottom:before {
  content: "\f179";
}
.fi-rs-arrow-to-left:before {
  content: "\f17a";
}
.fi-rs-arrow-to-right:before {
  content: "\f17b";
}
.fi-rs-arrow-to-top:before {
  content: "\f17c";
}
.fi-rs-arrow-trend-down:before {
  content: "\f17d";
}
.fi-rs-arrow-trend-up:before {
  content: "\f17e";
}
.fi-rs-arrow-turn-down-left:before {
  content: "\f17f";
}
.fi-rs-arrow-turn-down-right:before {
  content: "\f180";
}
.fi-rs-arrow-up-from-dotted-line:before {
  content: "\f181";
}
.fi-rs-arrow-up-from-square:before {
  content: "\f182";
}
.fi-rs-arrow-up-left-from-circle:before {
  content: "\f183";
}
.fi-rs-arrow-up-left:before {
  content: "\f184";
}
.fi-rs-arrow-up-right-and-arrow-down-left-from-center:before {
  content: "\f185";
}
.fi-rs-arrow-up-right-from-square:before {
  content: "\f186";
}
.fi-rs-arrow-up-right:before {
  content: "\f187";
}
.fi-rs-arrow-up-small-big:before {
  content: "\f188";
}
.fi-rs-arrow-up-square-triangle:before {
  content: "\f189";
}
.fi-rs-arrow-up-to-dotted-line:before {
  content: "\f18a";
}
.fi-rs-arrow-up:before {
  content: "\f18b";
}
.fi-rs-arrows-alt-h:before {
  content: "\f18c";
}
.fi-rs-arrows-alt-v:before {
  content: "\f18d";
}
.fi-rs-arrows-alt:before {
  content: "\f18e";
}
.fi-rs-arrows-cross:before {
  content: "\f18f";
}
.fi-rs-arrows-from-dotted-line:before {
  content: "\f190";
}
.fi-rs-arrows-from-line:before {
  content: "\f191";
}
.fi-rs-arrows-h-copy:before {
  content: "\f192";
}
.fi-rs-arrows-h:before {
  content: "\f193";
}
.fi-rs-arrows-repeat-1:before {
  content: "\f194";
}
.fi-rs-arrows-repeat:before {
  content: "\f195";
}
.fi-rs-arrows-retweet:before {
  content: "\f196";
}
.fi-rs-arrows-to-dotted-line:before {
  content: "\f197";
}
.fi-rs-arrows-to-line:before {
  content: "\f198";
}
.fi-rs-arrows:before {
  content: "\f199";
}
.fi-rs-assept-document:before {
  content: "\f19a";
}
.fi-rs-assistive-listening-systems:before {
  content: "\f19b";
}
.fi-rs-asterik:before {
  content: "\f19c";
}
.fi-rs-at:before {
  content: "\f19d";
}
.fi-rs-attribution-pen:before {
  content: "\f19e";
}
.fi-rs-attribution-pencil:before {
  content: "\f19f";
}
.fi-rs-aubergine:before {
  content: "\f1a0";
}
.fi-rs-audio-description-slash:before {
  content: "\f1a1";
}
.fi-rs-austral-sign:before {
  content: "\f1a2";
}
.fi-rs-avocado:before {
  content: "\f1a3";
}
.fi-rs-b:before {
  content: "\f1a4";
}
.fi-rs-baby-carriage:before {
  content: "\f1a5";
}
.fi-rs-baby:before {
  content: "\f1a6";
}
.fi-rs-background:before {
  content: "\f1a7";
}
.fi-rs-backpack:before {
  content: "\f1a8";
}
.fi-rs-bacon:before {
  content: "\f1a9";
}
.fi-rs-bacteria:before {
  content: "\f1aa";
}
.fi-rs-bacterium:before {
  content: "\f1ab";
}
.fi-rs-badge-check:before {
  content: "\f1ac";
}
.fi-rs-badge-dollar:before {
  content: "\f1ad";
}
.fi-rs-badge-percent:before {
  content: "\f1ae";
}
.fi-rs-badge-sheriff:before {
  content: "\f1af";
}
.fi-rs-badge:before {
  content: "\f1b0";
}
.fi-rs-bags-shopping:before {
  content: "\f1b1";
}
.fi-rs-bahai:before {
  content: "\f1b2";
}
.fi-rs-baht-sign:before {
  content: "\f1b3";
}
.fi-rs-balance-scale-left:before {
  content: "\f1b4";
}
.fi-rs-balance-scale-right:before {
  content: "\f1b5";
}
.fi-rs-ball-pile:before {
  content: "\f1b6";
}
.fi-rs-balloons:before {
  content: "\f1b7";
}
.fi-rs-ballot-check:before {
  content: "\f1b8";
}
.fi-rs-ballot:before {
  content: "\f1b9";
}
.fi-rs-ban-bug:before {
  content: "\f1ba";
}
.fi-rs-ban:before {
  content: "\f1bb";
}
.fi-rs-band-aid:before {
  content: "\f1bc";
}
.fi-rs-bangladeshi-taka-sign:before {
  content: "\f1bd";
}
.fi-rs-banjo:before {
  content: "\f1be";
}
.fi-rs-bank:before {
  content: "\f1bf";
}
.fi-rs-barber-shop:before {
  content: "\f1c0";
}
.fi-rs-barcode-read:before {
  content: "\f1c1";
}
.fi-rs-barcode-scan:before {
  content: "\f1c2";
}
.fi-rs-barcode:before {
  content: "\f1c3";
}
.fi-rs-bars-filter:before {
  content: "\f1c4";
}
.fi-rs-bars-progress:before {
  content: "\f1c5";
}
.fi-rs-bars-sort:before {
  content: "\f1c6";
}
.fi-rs-bars-staggered:before {
  content: "\f1c7";
}
.fi-rs-baseball-alt:before {
  content: "\f1c8";
}
.fi-rs-baseball:before {
  content: "\f1c9";
}
.fi-rs-basket-shopping-simple:before {
  content: "\f1ca";
}
.fi-rs-basketball-hoop:before {
  content: "\f1cb";
}
.fi-rs-basketball:before {
  content: "\f1cc";
}
.fi-rs-battery-bolt:before {
  content: "\f1cd";
}
.fi-rs-battery-empty:before {
  content: "\f1ce";
}
.fi-rs-battery-exclamation:before {
  content: "\f1cf";
}
.fi-rs-battery-full:before {
  content: "\f1d0";
}
.fi-rs-battery-half:before {
  content: "\f1d1";
}
.fi-rs-battery-quarter:before {
  content: "\f1d2";
}
.fi-rs-battery-slash:before {
  content: "\f1d3";
}
.fi-rs-battery-three-quarters:before {
  content: "\f1d4";
}
.fi-rs-beacon:before {
  content: "\f1d5";
}
.fi-rs-bed-alt:before {
  content: "\f1d6";
}
.fi-rs-bed:before {
  content: "\f1d7";
}
.fi-rs-beer:before {
  content: "\f1d8";
}
.fi-rs-bell-concierge:before {
  content: "\f1d9";
}
.fi-rs-bell-ring:before {
  content: "\f1da";
}
.fi-rs-bell-school:before {
  content: "\f1db";
}
.fi-rs-bell-slash:before {
  content: "\f1dc";
}
.fi-rs-bell:before {
  content: "\f1dd";
}
.fi-rs-bells:before {
  content: "\f1de";
}
.fi-rs-bench-tree:before {
  content: "\f1df";
}
.fi-rs-betamax:before {
  content: "\f1e0";
}
.fi-rs-bike:before {
  content: "\f1e1";
}
.fi-rs-biking-mountain:before {
  content: "\f1e2";
}
.fi-rs-biking:before {
  content: "\f1e3";
}
.fi-rs-billiard:before {
  content: "\f1e4";
}
.fi-rs-binary-circle-check:before {
  content: "\f1e5";
}
.fi-rs-binary-lock:before {
  content: "\f1e6";
}
.fi-rs-binary-slash:before {
  content: "\f1e7";
}
.fi-rs-binary:before {
  content: "\f1e8";
}
.fi-rs-binoculars:before {
  content: "\f1e9";
}
.fi-rs-biohazard:before {
  content: "\f1ea";
}
.fi-rs-bitcoin-sign:before {
  content: "\f1eb";
}
.fi-rs-blender-phone:before {
  content: "\f1ec";
}
.fi-rs-blinds-open:before {
  content: "\f1ed";
}
.fi-rs-blinds-raised:before {
  content: "\f1ee";
}
.fi-rs-blinds:before {
  content: "\f1ef";
}
.fi-rs-block-brick:before {
  content: "\f1f0";
}
.fi-rs-block-quote:before {
  content: "\f1f1";
}
.fi-rs-block:before {
  content: "\f1f2";
}
.fi-rs-blog-pencil:before {
  content: "\f1f3";
}
.fi-rs-blog-text:before {
  content: "\f1f4";
}
.fi-rs-bold:before {
  content: "\f1f5";
}
.fi-rs-bolt-auto:before {
  content: "\f1f6";
}
.fi-rs-bolt-slash:before {
  content: "\f1f7";
}
.fi-rs-bolt:before {
  content: "\f1f8";
}
.fi-rs-bomb:before {
  content: "\f1f9";
}
.fi-rs-bone-break:before {
  content: "\f1fa";
}
.fi-rs-bone:before {
  content: "\f1fb";
}
.fi-rs-bong:before {
  content: "\f1fc";
}
.fi-rs-book-alt:before {
  content: "\f1fd";
}
.fi-rs-book-arrow-right:before {
  content: "\f1fe";
}
.fi-rs-book-arrow-up:before {
  content: "\f1ff";
}
.fi-rs-book-atlas:before {
  content: "\f200";
}
.fi-rs-book-bookmark:before {
  content: "\f201";
}
.fi-rs-book-circle-arrow-right:before {
  content: "\f202";
}
.fi-rs-book-circle-arrow-up:before {
  content: "\f203";
}
.fi-rs-book-copy:before {
  content: "\f204";
}
.fi-rs-book-dead:before {
  content: "\f205";
}
.fi-rs-book-font:before {
  content: "\f206";
}
.fi-rs-book-medical:before {
  content: "\f207";
}
.fi-rs-book-open-cover:before {
  content: "\f208";
}
.fi-rs-book-open-reader:before {
  content: "\f209";
}
.fi-rs-book-quran:before {
  content: "\f20a";
}
.fi-rs-book-section:before {
  content: "\f20b";
}
.fi-rs-book-spells:before {
  content: "\f20c";
}
.fi-rs-book-tanakh:before {
  content: "\f20d";
}
.fi-rs-book-user:before {
  content: "\f20e";
}
.fi-rs-book:before {
  content: "\f20f";
}
.fi-rs-bookmark-slash:before {
  content: "\f210";
}
.fi-rs-bookmark:before {
  content: "\f211";
}
.fi-rs-books-medical:before {
  content: "\f212";
}
.fi-rs-books:before {
  content: "\f213";
}
.fi-rs-border-all:before {
  content: "\f214";
}
.fi-rs-border-bottom:before {
  content: "\f215";
}
.fi-rs-border-center-h:before {
  content: "\f216";
}
.fi-rs-border-center-v:before {
  content: "\f217";
}
.fi-rs-border-inner:before {
  content: "\f218";
}
.fi-rs-border-left:before {
  content: "\f219";
}
.fi-rs-border-none:before {
  content: "\f21a";
}
.fi-rs-border-outer:before {
  content: "\f21b";
}
.fi-rs-border-right:before {
  content: "\f21c";
}
.fi-rs-border-style-alt:before {
  content: "\f21d";
}
.fi-rs-border-style:before {
  content: "\f21e";
}
.fi-rs-border-top:before {
  content: "\f21f";
}
.fi-rs-bottle:before {
  content: "\f220";
}
.fi-rs-bow-arrow:before {
  content: "\f221";
}
.fi-rs-bowl-chopsticks-noodles:before {
  content: "\f222";
}
.fi-rs-bowl-chopsticks:before {
  content: "\f223";
}
.fi-rs-bowl-rice:before {
  content: "\f224";
}
.fi-rs-bowling-ball:before {
  content: "\f225";
}
.fi-rs-bowling-pins:before {
  content: "\f226";
}
.fi-rs-bowling:before {
  content: "\f227";
}
.fi-rs-box-alt:before {
  content: "\f228";
}
.fi-rs-box-ballot:before {
  content: "\f229";
}
.fi-rs-box-check:before {
  content: "\f22a";
}
.fi-rs-box-dollar:before {
  content: "\f22b";
}
.fi-rs-box-fragile:before {
  content: "\f22c";
}
.fi-rs-box-heart:before {
  content: "\f22d";
}
.fi-rs-box-open-full:before {
  content: "\f22e";
}
.fi-rs-box-open:before {
  content: "\f22f";
}
.fi-rs-box-tissue:before {
  content: "\f230";
}
.fi-rs-box-up:before {
  content: "\f231";
}
.fi-rs-box:before {
  content: "\f232";
}
.fi-rs-boxes:before {
  content: "\f233";
}
.fi-rs-boxing-glove:before {
  content: "\f234";
}
.fi-rs-bracket-curly-right:before {
  content: "\f235";
}
.fi-rs-bracket-curly:before {
  content: "\f236";
}
.fi-rs-bracket-round-right:before {
  content: "\f237";
}
.fi-rs-bracket-round:before {
  content: "\f238";
}
.fi-rs-bracket-square-right:before {
  content: "\f239";
}
.fi-rs-bracket-square:before {
  content: "\f23a";
}
.fi-rs-brackets-curly:before {
  content: "\f23b";
}
.fi-rs-brackets-round:before {
  content: "\f23c";
}
.fi-rs-brackets-square:before {
  content: "\f23d";
}
.fi-rs-braille:before {
  content: "\f23e";
}
.fi-rs-brain-circuit:before {
  content: "\f23f";
}
.fi-rs-brain:before {
  content: "\f240";
}
.fi-rs-bread-loaf:before {
  content: "\f241";
}
.fi-rs-bread-slice:before {
  content: "\f242";
}
.fi-rs-bread:before {
  content: "\f243";
}
.fi-rs-briefcase-arrow-right:before {
  content: "\f244";
}
.fi-rs-briefcase-blank:before {
  content: "\f245";
}
.fi-rs-briefcase:before {
  content: "\f246";
}
.fi-rs-brightness-low:before {
  content: "\f247";
}
.fi-rs-brightness:before {
  content: "\f248";
}
.fi-rs-bring-forward:before {
  content: "\f249";
}
.fi-rs-bring-front:before {
  content: "\f24a";
}
.fi-rs-broadcast-tower:before {
  content: "\f24b";
}
.fi-rs-broccoli:before {
  content: "\f24c";
}
.fi-rs-broken-image:before {
  content: "\f24d";
}
.fi-rs-browser:before {
  content: "\f24e";
}
.fi-rs-browsers:before {
  content: "\f24f";
}
.fi-rs-brush:before {
  content: "\f250";
}
.fi-rs-bug-slash:before {
  content: "\f251";
}
.fi-rs-bug:before {
  content: "\f252";
}
.fi-rs-building:before {
  content: "\f253";
}
.fi-rs-bulb:before {
  content: "\f254";
}
.fi-rs-bullet:before {
  content: "\f255";
}
.fi-rs-bullhorn:before {
  content: "\f256";
}
.fi-rs-bullseye-arrow:before {
  content: "\f257";
}
.fi-rs-bullseye-pointer:before {
  content: "\f258";
}
.fi-rs-bullseye:before {
  content: "\f259";
}
.fi-rs-burger-alt:before {
  content: "\f25a";
}
.fi-rs-burger-fries:before {
  content: "\f25b";
}
.fi-rs-burger-glass:before {
  content: "\f25c";
}
.fi-rs-burger-menu:before {
  content: "\f25d";
}
.fi-rs-burrito:before {
  content: "\f25e";
}
.fi-rs-bus-alt:before {
  content: "\f25f";
}
.fi-rs-bus:before {
  content: "\f260";
}
.fi-rs-business-time:before {
  content: "\f261";
}
.fi-rs-butterfly:before {
  content: "\f262";
}
.fi-rs-c:before {
  content: "\f263";
}
.fi-rs-cabin:before {
  content: "\f264";
}
.fi-rs-cactus:before {
  content: "\f265";
}
.fi-rs-cake-birthday:before {
  content: "\f266";
}
.fi-rs-cake-wedding:before {
  content: "\f267";
}
.fi-rs-calculator-simple:before {
  content: "\f268";
}
.fi-rs-calculator:before {
  content: "\f269";
}
.fi-rs-calendar-arrow-down:before {
  content: "\f26a";
}
.fi-rs-calendar-arrow-up:before {
  content: "\f26b";
}
.fi-rs-calendar-check:before {
  content: "\f26c";
}
.fi-rs-calendar-clock:before {
  content: "\f26d";
}
.fi-rs-calendar-day:before {
  content: "\f26e";
}
.fi-rs-calendar-days:before {
  content: "\f26f";
}
.fi-rs-calendar-exclamation:before {
  content: "\f270";
}
.fi-rs-calendar-heart:before {
  content: "\f271";
}
.fi-rs-calendar-image:before {
  content: "\f272";
}
.fi-rs-calendar-lines-pen:before {
  content: "\f273";
}
.fi-rs-calendar-lines:before {
  content: "\f274";
}
.fi-rs-calendar-minus:before {
  content: "\f275";
}
.fi-rs-calendar-pen:before {
  content: "\f276";
}
.fi-rs-calendar-plus:before {
  content: "\f277";
}
.fi-rs-calendar-star:before {
  content: "\f278";
}
.fi-rs-calendar-week:before {
  content: "\f279";
}
.fi-rs-calendar-xmark:before {
  content: "\f27a";
}
.fi-rs-calendar:before {
  content: "\f27b";
}
.fi-rs-calendars:before {
  content: "\f27c";
}
.fi-rs-call-history:before {
  content: "\f27d";
}
.fi-rs-call-incoming:before {
  content: "\f27e";
}
.fi-rs-call-missed:before {
  content: "\f27f";
}
.fi-rs-call-outgoing:before {
  content: "\f280";
}
.fi-rs-camcorder:before {
  content: "\f281";
}
.fi-rs-camera-cctv:before {
  content: "\f282";
}
.fi-rs-camera-movie:before {
  content: "\f283";
}
.fi-rs-camera-retro:before {
  content: "\f284";
}
.fi-rs-camera-rotate:before {
  content: "\f285";
}
.fi-rs-camera-security:before {
  content: "\f286";
}
.fi-rs-camera-slash:before {
  content: "\f287";
}
.fi-rs-camera-viewfinder:before {
  content: "\f288";
}
.fi-rs-camera:before {
  content: "\f289";
}
.fi-rs-campfire:before {
  content: "\f28a";
}
.fi-rs-camping:before {
  content: "\f28b";
}
.fi-rs-candle-holder:before {
  content: "\f28c";
}
.fi-rs-candy-alt:before {
  content: "\f28d";
}
.fi-rs-candy-cane:before {
  content: "\f28e";
}
.fi-rs-candy-corn:before {
  content: "\f28f";
}
.fi-rs-candy:before {
  content: "\f290";
}
.fi-rs-Cannabis:before {
  content: "\f291";
}
.fi-rs-canned-food:before {
  content: "\f292";
}
.fi-rs-capsules:before {
  content: "\f293";
}
.fi-rs-car-alt:before {
  content: "\f294";
}
.fi-rs-car-battery:before {
  content: "\f295";
}
.fi-rs-car-bolt:before {
  content: "\f296";
}
.fi-rs-car-building:before {
  content: "\f297";
}
.fi-rs-car-bump:before {
  content: "\f298";
}
.fi-rs-car-bus:before {
  content: "\f299";
}
.fi-rs-car-circle-bolt:before {
  content: "\f29a";
}
.fi-rs-car-crash:before {
  content: "\f29b";
}
.fi-rs-car-garage:before {
  content: "\f29c";
}
.fi-rs-car-mechanic:before {
  content: "\f29d";
}
.fi-rs-car-side-bolt:before {
  content: "\f29e";
}
.fi-rs-car-side:before {
  content: "\f29f";
}
.fi-rs-car-tilt:before {
  content: "\f2a0";
}
.fi-rs-car-wash:before {
  content: "\f2a1";
}
.fi-rs-car:before {
  content: "\f2a2";
}
.fi-rs-caravan-alt:before {
  content: "\f2a3";
}
.fi-rs-caravan:before {
  content: "\f2a4";
}
.fi-rs-caret-circle-down:before {
  content: "\f2a5";
}
.fi-rs-caret-circle-right:before {
  content: "\f2a6";
}
.fi-rs-caret-circle-up:before {
  content: "\f2a7";
}
.fi-rs-caret-down:before {
  content: "\f2a8";
}
.fi-rs-caret-left:before {
  content: "\f2a9";
}
.fi-rs-caret-quare-up:before {
  content: "\f2aa";
}
.fi-rs-caret-right:before {
  content: "\f2ab";
}
.fi-rs-caret-square-down:before {
  content: "\f2ac";
}
.fi-rs-caret-square-left_1:before {
  content: "\f2ad";
}
.fi-rs-caret-square-left:before {
  content: "\f2ae";
}
.fi-rs-caret-square-right:before {
  content: "\f2af";
}
.fi-rs-caret-up:before {
  content: "\f2b0";
}
.fi-rs-carrot:before {
  content: "\f2b1";
}
.fi-rs-cars:before {
  content: "\f2b2";
}
.fi-rs-cart-arrow-down:before {
  content: "\f2b3";
}
.fi-rs-cart-minus:before {
  content: "\f2b4";
}
.fi-rs-cart-shopping-fast:before {
  content: "\f2b5";
}
.fi-rs-cash-register:before {
  content: "\f2b6";
}
.fi-rs-cassette-tape:before {
  content: "\f2b7";
}
.fi-rs-cassette-vhs:before {
  content: "\f2b8";
}
.fi-rs-castle:before {
  content: "\f2b9";
}
.fi-rs-cauldron:before {
  content: "\f2ba";
}
.fi-rs-cedi-sign:before {
  content: "\f2bb";
}
.fi-rs-cello:before {
  content: "\f2bc";
}
.fi-rs-cent-sign:before {
  content: "\f2bd";
}
.fi-rs-chair-office:before {
  content: "\f2be";
}
.fi-rs-chair:before {
  content: "\f2bf";
}
.fi-rs-chalkboard-user:before {
  content: "\f2c0";
}
.fi-rs-charging-station:before {
  content: "\f2c1";
}
.fi-rs-chart-area:before {
  content: "\f2c2";
}
.fi-rs-chart-bullet:before {
  content: "\f2c3";
}
.fi-rs-chart-candlestick:before {
  content: "\f2c4";
}
.fi-rs-chart-connected:before {
  content: "\f2c5";
}
.fi-rs-chart-gantt:before {
  content: "\f2c6";
}
.fi-rs-chart-histogram:before {
  content: "\f2c7";
}
.fi-rs-chart-line-up:before {
  content: "\f2c8";
}
.fi-rs-chart-mixed:before {
  content: "\f2c9";
}
.fi-rs-chart-network:before {
  content: "\f2ca";
}
.fi-rs-chart-pie-alt:before {
  content: "\f2cb";
}
.fi-rs-chart-pie:before {
  content: "\f2cc";
}
.fi-rs-chart-pyramid:before {
  content: "\f2cd";
}
.fi-rs-chart-radar:before {
  content: "\f2ce";
}
.fi-rs-chart-scatter-3d:before {
  content: "\f2cf";
}
.fi-rs-chart-scatter-bubble:before {
  content: "\f2d0";
}
.fi-rs-chart-scatter:before {
  content: "\f2d1";
}
.fi-rs-chart-set-theory:before {
  content: "\f2d2";
}
.fi-rs-chart-simple-horizontal:before {
  content: "\f2d3";
}
.fi-rs-chart-simple:before {
  content: "\f2d4";
}
.fi-rs-chart-tree-map:before {
  content: "\f2d5";
}
.fi-rs-chart-tree:before {
  content: "\f2d6";
}
.fi-rs-chart-user:before {
  content: "\f2d7";
}
.fi-rs-chart-waterfall:before {
  content: "\f2d8";
}
.fi-rs-chat-arrow-down:before {
  content: "\f2d9";
}
.fi-rs-chat-arrow-grow:before {
  content: "\f2da";
}
.fi-rs-check-circle:before {
  content: "\f2db";
}
.fi-rs-check-double:before {
  content: "\f2dc";
}
.fi-rs-check:before {
  content: "\f2dd";
}
.fi-rs-checkbox:before {
  content: "\f2de";
}
.fi-rs-cheese-alt:before {
  content: "\f2df";
}
.fi-rs-cheese:before {
  content: "\f2e0";
}
.fi-rs-cheeseburger:before {
  content: "\f2e1";
}
.fi-rs-cherry:before {
  content: "\f2e2";
}
.fi-rs-chess-bishop:before {
  content: "\f2e3";
}
.fi-rs-chess-board:before {
  content: "\f2e4";
}
.fi-rs-chess-clock-alt:before {
  content: "\f2e5";
}
.fi-rs-chess-clock:before {
  content: "\f2e6";
}
.fi-rs-chess-king-alt:before {
  content: "\f2e7";
}
.fi-rs-chess-king:before {
  content: "\f2e8";
}
.fi-rs-chess-knight-alt:before {
  content: "\f2e9";
}
.fi-rs-chess-knight:before {
  content: "\f2ea";
}
.fi-rs-chess-pawn-alt:before {
  content: "\f2eb";
}
.fi-rs-chess-pawn:before {
  content: "\f2ec";
}
.fi-rs-chess-piece:before {
  content: "\f2ed";
}
.fi-rs-chess-queen-alt:before {
  content: "\f2ee";
}
.fi-rs-chess-queen:before {
  content: "\f2ef";
}
.fi-rs-chess-rook-alt:before {
  content: "\f2f0";
}
.fi-rs-chess-rook:before {
  content: "\f2f1";
}
.fi-rs-chess:before {
  content: "\f2f2";
}
.fi-rs-chevron-double-down:before {
  content: "\f2f3";
}
.fi-rs-chevron-double-up:before {
  content: "\f2f4";
}
.fi-rs-child-head:before {
  content: "\f2f5";
}
.fi-rs-chocolate:before {
  content: "\f2f6";
}
.fi-rs-circle-0:before {
  content: "\f2f7";
}
.fi-rs-circle-1:before {
  content: "\f2f8";
}
.fi-rs-circle-2:before {
  content: "\f2f9";
}
.fi-rs-circle-3:before {
  content: "\f2fa";
}
.fi-rs-circle-4:before {
  content: "\f2fb";
}
.fi-rs-circle-5:before {
  content: "\f2fc";
}
.fi-rs-circle-6:before {
  content: "\f2fd";
}
.fi-rs-circle-7:before {
  content: "\f2fe";
}
.fi-rs-circle-8:before {
  content: "\f2ff";
}
.fi-rs-circle-9:before {
  content: "\f300";
}
.fi-rs-circle-a:before {
  content: "\f301";
}
.fi-rs-circle-b:before {
  content: "\f302";
}
.fi-rs-circle-bolt:before {
  content: "\f303";
}
.fi-rs-circle-book-open:before {
  content: "\f304";
}
.fi-rs-circle-bookmark:before {
  content: "\f305";
}
.fi-rs-circle-c:before {
  content: "\f306";
}
.fi-rs-circle-calendar:before {
  content: "\f307";
}
.fi-rs-circle-camera:before {
  content: "\f308";
}
.fi-rs-circle-cross:before {
  content: "\f309";
}
.fi-rs-circle-d:before {
  content: "\f30a";
}
.fi-rs-circle-dashed:before {
  content: "\f30b";
}
.fi-rs-circle-e:before {
  content: "\f30c";
}
.fi-rs-circle-ellipsis-vertical:before {
  content: "\f30d";
}
.fi-rs-circle-ellipsis:before {
  content: "\f30e";
}
.fi-rs-circle-envelope:before {
  content: "\f30f";
}
.fi-rs-circle-exclamation-check:before {
  content: "\f310";
}
.fi-rs-circle-f:before {
  content: "\f311";
}
.fi-rs-circle-g:before {
  content: "\f312";
}
.fi-rs-circle-h:before {
  content: "\f313";
}
.fi-rs-circle-half-stroke:before {
  content: "\f314";
}
.fi-rs-circle-half:before {
  content: "\f315";
}
.fi-rs-circle-heart:before {
  content: "\f316";
}
.fi-rs-circle-i:before {
  content: "\f317";
}
.fi-rs-circle-j:before {
  content: "\f318";
}
.fi-rs-circle-k:before {
  content: "\f319";
}
.fi-rs-circle-l:before {
  content: "\f31a";
}
.fi-rs-circle-m:before {
  content: "\f31b";
}
.fi-rs-circle-microphone-lines:before {
  content: "\f31c";
}
.fi-rs-circle-microphone:before {
  content: "\f31d";
}
.fi-rs-circle-n:before {
  content: "\f31e";
}
.fi-rs-circle-o:before {
  content: "\f31f";
}
.fi-rs-circle-p:before {
  content: "\f320";
}
.fi-rs-circle-phone-flip:before {
  content: "\f321";
}
.fi-rs-circle-phone-hangup:before {
  content: "\f322";
}
.fi-rs-circle-phone:before {
  content: "\f323";
}
.fi-rs-circle-q:before {
  content: "\f324";
}
.fi-rs-circle-quarter:before {
  content: "\f325";
}
.fi-rs-circle-quarters-alt:before {
  content: "\f326";
}
.fi-rs-circle-r:before {
  content: "\f327";
}
.fi-rs-circle-s:before {
  content: "\f328";
}
.fi-rs-circle-small:before {
  content: "\f329";
}
.fi-rs-circle-star:before {
  content: "\f32a";
}
.fi-rs-circle-t:before {
  content: "\f32b";
}
.fi-rs-circle-three-quarters:before {
  content: "\f32c";
}
.fi-rs-circle-trash:before {
  content: "\f32d";
}
.fi-rs-circle-u:before {
  content: "\f32e";
}
.fi-rs-circle-user:before {
  content: "\f32f";
}
.fi-rs-circle-v:before {
  content: "\f330";
}
.fi-rs-circle-video:before {
  content: "\f331";
}
.fi-rs-circle-w:before {
  content: "\f332";
}
.fi-rs-circle-waveform-lines:before {
  content: "\f333";
}
.fi-rs-circle-x:before {
  content: "\f334";
}
.fi-rs-circle-xmark:before {
  content: "\f335";
}
.fi-rs-circle-y:before {
  content: "\f336";
}
.fi-rs-circle-z:before {
  content: "\f337";
}
.fi-rs-circle:before {
  content: "\f338";
}
.fi-rs-city:before {
  content: "\f339";
}
.fi-rs-clapperboard-play:before {
  content: "\f33a";
}
.fi-rs-clapperboard:before {
  content: "\f33b";
}
.fi-rs-clarinet:before {
  content: "\f33c";
}
.fi-rs-claw-marks:before {
  content: "\f33d";
}
.fi-rs-clean:before {
  content: "\f33e";
}
.fi-rs-clip:before {
  content: "\f33f";
}
.fi-rs-clipboard-list-check:before {
  content: "\f340";
}
.fi-rs-clipboard-list:before {
  content: "\f341";
}
.fi-rs-clipboard-prescription:before {
  content: "\f342";
}
.fi-rs-clipboard-user:before {
  content: "\f343";
}
.fi-rs-clipboard:before {
  content: "\f344";
}
.fi-rs-clock-eight-thirty:before {
  content: "\f345";
}
.fi-rs-clock-eleven-thirty:before {
  content: "\f346";
}
.fi-rs-clock-eleven:before {
  content: "\f347";
}
.fi-rs-clock-five-thirty:before {
  content: "\f348";
}
.fi-rs-clock-five:before {
  content: "\f349";
}
.fi-rs-clock-four-thirty:before {
  content: "\f34a";
}
.fi-rs-clock-nine-thirty:before {
  content: "\f34b";
}
.fi-rs-clock-nine:before {
  content: "\f34c";
}
.fi-rs-clock-one-thirty:before {
  content: "\f34d";
}
.fi-rs-clock-one:before {
  content: "\f34e";
}
.fi-rs-clock-seven-thirty:before {
  content: "\f34f";
}
.fi-rs-clock-seven:before {
  content: "\f350";
}
.fi-rs-clock-six-thirty:before {
  content: "\f351";
}
.fi-rs-clock-six:before {
  content: "\f352";
}
.fi-rs-clock-ten-thirty:before {
  content: "\f353";
}
.fi-rs-clock-ten:before {
  content: "\f354";
}
.fi-rs-clock-three-thirty:before {
  content: "\f355";
}
.fi-rs-clock-three:before {
  content: "\f356";
}
.fi-rs-clock-twelve-thirty:before {
  content: "\f357";
}
.fi-rs-clock-twelve:before {
  content: "\f358";
}
.fi-rs-clock-two-thirty:before {
  content: "\f359";
}
.fi-rs-clock-two:before {
  content: "\f35a";
}
.fi-rs-clock:before {
  content: "\f35b";
}
.fi-rs-clone:before {
  content: "\f35c";
}
.fi-rs-closed-captioning-slash:before {
  content: "\f35d";
}
.fi-rs-cloud-check:before {
  content: "\f35e";
}
.fi-rs-cloud-disabled:before {
  content: "\f35f";
}
.fi-rs-cloud-download-alt:before {
  content: "\f360";
}
.fi-rs-cloud-download:before {
  content: "\f361";
}
.fi-rs-cloud-drizzle:before {
  content: "\f362";
}
.fi-rs-cloud-exclamation:before {
  content: "\f363";
}
.fi-rs-cloud-hail-mixed:before {
  content: "\f364";
}
.fi-rs-cloud-hail:before {
  content: "\f365";
}
.fi-rs-cloud-meatball:before {
  content: "\f366";
}
.fi-rs-cloud-moon-rain:before {
  content: "\f367";
}
.fi-rs-cloud-moon:before {
  content: "\f368";
}
.fi-rs-cloud-question:before {
  content: "\f369";
}
.fi-rs-cloud-rain:before {
  content: "\f36a";
}
.fi-rs-cloud-rainbow:before {
  content: "\f36b";
}
.fi-rs-cloud-share:before {
  content: "\f36c";
}
.fi-rs-cloud-showers-heavy:before {
  content: "\f36d";
}
.fi-rs-cloud-showers:before {
  content: "\f36e";
}
.fi-rs-cloud-sleet:before {
  content: "\f36f";
}
.fi-rs-cloud-snow:before {
  content: "\f370";
}
.fi-rs-cloud-sun-rain:before {
  content: "\f371";
}
.fi-rs-cloud-sun:before {
  content: "\f372";
}
.fi-rs-cloud-upload-alt:before {
  content: "\f373";
}
.fi-rs-cloud-upload:before {
  content: "\f374";
}
.fi-rs-cloud:before {
  content: "\f375";
}
.fi-rs-clouds-moon:before {
  content: "\f376";
}
.fi-rs-clouds-sun:before {
  content: "\f377";
}
.fi-rs-clouds:before {
  content: "\f378";
}
.fi-rs-club:before {
  content: "\f379";
}
.fi-rs-cocktail-alt:before {
  content: "\f37a";
}
.fi-rs-cocktail:before {
  content: "\f37b";
}
.fi-rs-code-branch:before {
  content: "\f37c";
}
.fi-rs-code-commit:before {
  content: "\f37d";
}
.fi-rs-code-compare:before {
  content: "\f37e";
}
.fi-rs-code-fork:before {
  content: "\f37f";
}
.fi-rs-code-merge:before {
  content: "\f380";
}
.fi-rs-code-pull-request-closed:before {
  content: "\f381";
}
.fi-rs-code-pull-request-draft:before {
  content: "\f382";
}
.fi-rs-code-pull-request:before {
  content: "\f383";
}
.fi-rs-code-simple:before {
  content: "\f384";
}
.fi-rs-coffee-bean:before {
  content: "\f385";
}
.fi-rs-coffee-beans:before {
  content: "\f386";
}
.fi-rs-coffee-pot:before {
  content: "\f387";
}
.fi-rs-coffee:before {
  content: "\f388";
}
.fi-rs-coffin-cross:before {
  content: "\f389";
}
.fi-rs-coffin:before {
  content: "\f38a";
}
.fi-rs-coin:before {
  content: "\f38b";
}
.fi-rs-coins:before {
  content: "\f38c";
}
.fi-rs-colon-sign:before {
  content: "\f38d";
}
.fi-rs-columns-3:before {
  content: "\f38e";
}
.fi-rs-comet:before {
  content: "\f38f";
}
.fi-rs-command:before {
  content: "\f390";
}
.fi-rs-comment-alt-check:before {
  content: "\f391";
}
.fi-rs-comment-alt-dots:before {
  content: "\f392";
}
.fi-rs-comment-alt-edit:before {
  content: "\f393";
}
.fi-rs-comment-alt-medical:before {
  content: "\f394";
}
.fi-rs-comment-alt-middle-top:before {
  content: "\f395";
}
.fi-rs-comment-alt-middle:before {
  content: "\f396";
}
.fi-rs-comment-alt-minus:before {
  content: "\f397";
}
.fi-rs-comment-alt-music:before {
  content: "\f398";
}
.fi-rs-comment-alt:before {
  content: "\f399";
}
.fi-rs-comment-arrow-down:before {
  content: "\f39a";
}
.fi-rs-comment-arrow-up-right:before {
  content: "\f39b";
}
.fi-rs-comment-arrow-up:before {
  content: "\f39c";
}
.fi-rs-comment-check:before {
  content: "\f39d";
}
.fi-rs-comment-code:before {
  content: "\f39e";
}
.fi-rs-comment-dollar:before {
  content: "\f39f";
}
.fi-rs-comment-dots:before {
  content: "\f3a0";
}
.fi-rs-comment-exclamation:before {
  content: "\f3a1";
}
.fi-rs-comment-heart:before {
  content: "\f3a2";
}
.fi-rs-comment-image:before {
  content: "\f3a3";
}
.fi-rs-comment-info:before {
  content: "\f3a4";
}
.fi-rs-comment-medical:before {
  content: "\f3a5";
}
.fi-rs-comment-minus:before {
  content: "\f3a6";
}
.fi-rs-comment-pen:before {
  content: "\f3a7";
}
.fi-rs-comment-question:before {
  content: "\f3a8";
}
.fi-rs-comment-quote:before {
  content: "\f3a9";
}
.fi-rs-comment-slash:before {
  content: "\f3aa";
}
.fi-rs-comment-smile:before {
  content: "\f3ab";
}
.fi-rs-comment-sms:before {
  content: "\f3ac";
}
.fi-rs-comment-text:before {
  content: "\f3ad";
}
.fi-rs-comment-user:before {
  content: "\f3ae";
}
.fi-rs-comment-xmark:before {
  content: "\f3af";
}
.fi-rs-comment:before {
  content: "\f3b0";
}
.fi-rs-comments-dollar:before {
  content: "\f3b1";
}
.fi-rs-comments-question-check:before {
  content: "\f3b2";
}
.fi-rs-comments-question:before {
  content: "\f3b3";
}
.fi-rs-comments:before {
  content: "\f3b4";
}
.fi-rs-compass-slash:before {
  content: "\f3b5";
}
.fi-rs-compress-alt:before {
  content: "\f3b6";
}
.fi-rs-compress:before {
  content: "\f3b7";
}
.fi-rs-computer-speaker:before {
  content: "\f3b8";
}
.fi-rs-computer:before {
  content: "\f3b9";
}
.fi-rs-concierge-bell:before {
  content: "\f3ba";
}
.fi-rs-confetti:before {
  content: "\f3bb";
}
.fi-rs-cookie-alt:before {
  content: "\f3bc";
}
.fi-rs-cookie:before {
  content: "\f3bd";
}
.fi-rs-copy-alt:before {
  content: "\f3be";
}
.fi-rs-copy-image:before {
  content: "\f3bf";
}
.fi-rs-copy:before {
  content: "\f3c0";
}
.fi-rs-copyright:before {
  content: "\f3c1";
}
.fi-rs-corn:before {
  content: "\f3c2";
}
.fi-rs-cow:before {
  content: "\f3c3";
}
.fi-rs-cowbell-circle-plus:before {
  content: "\f3c4";
}
.fi-rs-cowbell-more:before {
  content: "\f3c5";
}
.fi-rs-cowbell:before {
  content: "\f3c6";
}
.fi-rs-cream:before {
  content: "\f3c7";
}
.fi-rs-credit-card:before {
  content: "\f3c8";
}
.fi-rs-cricket:before {
  content: "\f3c9";
}
.fi-rs-croissant:before {
  content: "\f3ca";
}
.fi-rs-cross-religion:before {
  content: "\f3cb";
}
.fi-rs-cross-small:before {
  content: "\f3cc";
}
.fi-rs-cross:before {
  content: "\f3cd";
}
.fi-rs-crossed-eye:before {
  content: "\f3ce";
}
.fi-rs-crown:before {
  content: "\f3cf";
}
.fi-rs-crutch:before {
  content: "\f3d0";
}
.fi-rs-crutches:before {
  content: "\f3d1";
}
.fi-rs-cruzeiro-sign:before {
  content: "\f3d2";
}
.fi-rs-crystal-ball:before {
  content: "\f3d3";
}
.fi-rs-cube:before {
  content: "\f3d4";
}
.fi-rs-cubes:before {
  content: "\f3d5";
}
.fi-rs-cupcake:before {
  content: "\f3d6";
}
.fi-rs-curling:before {
  content: "\f3d7";
}
.fi-rs-cursor-finger:before {
  content: "\f3d8";
}
.fi-rs-cursor-plus:before {
  content: "\f3d9";
}
.fi-rs-cursor-text-alt:before {
  content: "\f3da";
}
.fi-rs-cursor-text:before {
  content: "\f3db";
}
.fi-rs-cursor:before {
  content: "\f3dc";
}
.fi-rs-d:before {
  content: "\f3dd";
}
.fi-rs-dart:before {
  content: "\f3de";
}
.fi-rs-dashboard:before {
  content: "\f3df";
}
.fi-rs-data-transfer:before {
  content: "\f3e0";
}
.fi-rs-database:before {
  content: "\f3e1";
}
.fi-rs-delete-document:before {
  content: "\f3e2";
}
.fi-rs-delete-right:before {
  content: "\f3e3";
}
.fi-rs-delete-user:before {
  content: "\f3e4";
}
.fi-rs-delete:before {
  content: "\f3e5";
}
.fi-rs-democrat:before {
  content: "\f3e6";
}
.fi-rs-desktop-wallpaper:before {
  content: "\f3e7";
}
.fi-rs-devices:before {
  content: "\f3e8";
}
.fi-rs-dewpoint:before {
  content: "\f3e9";
}
.fi-rs-dharmachakra:before {
  content: "\f3ea";
}
.fi-rs-diagram-cells:before {
  content: "\f3eb";
}
.fi-rs-diagram-lean-canvas:before {
  content: "\f3ec";
}
.fi-rs-diagram-nested:before {
  content: "\f3ed";
}
.fi-rs-diagram-next:before {
  content: "\f3ee";
}
.fi-rs-diagram-predecessor:before {
  content: "\f3ef";
}
.fi-rs-diagram-previous:before {
  content: "\f3f0";
}
.fi-rs-diagram-project:before {
  content: "\f3f1";
}
.fi-rs-diagram-sankey:before {
  content: "\f3f2";
}
.fi-rs-diagram-subtask:before {
  content: "\f3f3";
}
.fi-rs-diagram-successor:before {
  content: "\f3f4";
}
.fi-rs-diagram-venn:before {
  content: "\f3f5";
}
.fi-rs-dial-high:before {
  content: "\f3f6";
}
.fi-rs-dial-low:before {
  content: "\f3f7";
}
.fi-rs-dial-max:before {
  content: "\f3f8";
}
.fi-rs-dial-med-low:before {
  content: "\f3f9";
}
.fi-rs-dial-med:before {
  content: "\f3fa";
}
.fi-rs-dial-min:before {
  content: "\f3fb";
}
.fi-rs-dial-off:before {
  content: "\f3fc";
}
.fi-rs-dial:before {
  content: "\f3fd";
}
.fi-rs-diamond-exclamation:before {
  content: "\f3fe";
}
.fi-rs-diamond-turn-right:before {
  content: "\f3ff";
}
.fi-rs-diamond:before {
  content: "\f400";
}
.fi-rs-dice-alt:before {
  content: "\f401";
}
.fi-rs-dice-d10:before {
  content: "\f402";
}
.fi-rs-dice-d12:before {
  content: "\f403";
}
.fi-rs-dice-d20:before {
  content: "\f404";
}
.fi-rs-dice-d4:before {
  content: "\f405";
}
.fi-rs-dice-d6:before {
  content: "\f406";
}
.fi-rs-dice-d8:before {
  content: "\f407";
}
.fi-rs-dice-four:before {
  content: "\f408";
}
.fi-rs-dice-one:before {
  content: "\f409";
}
.fi-rs-dice-six:before {
  content: "\f40a";
}
.fi-rs-dice-three:before {
  content: "\f40b";
}
.fi-rs-dice-two:before {
  content: "\f40c";
}
.fi-rs-dice:before {
  content: "\f40d";
}
.fi-rs-diploma:before {
  content: "\f40e";
}
.fi-rs-disco-ball:before {
  content: "\f40f";
}
.fi-rs-disease:before {
  content: "\f410";
}
.fi-rs-disk:before {
  content: "\f411";
}
.fi-rs-display-code:before {
  content: "\f412";
}
.fi-rs-distribute-spacing-horizontal:before {
  content: "\f413";
}
.fi-rs-distribute-spacing-vertical:before {
  content: "\f414";
}
.fi-rs-dizzy:before {
  content: "\f415";
}
.fi-rs-dna:before {
  content: "\f416";
}
.fi-rs-doctor:before {
  content: "\f417";
}
.fi-rs-document-signed:before {
  content: "\f418";
}
.fi-rs-document:before {
  content: "\f419";
}
.fi-rs-dollar:before {
  content: "\f41a";
}
.fi-rs-donate:before {
  content: "\f41b";
}
.fi-rs-dong-sign:before {
  content: "\f41c";
}
.fi-rs-door-closed:before {
  content: "\f41d";
}
.fi-rs-door-open:before {
  content: "\f41e";
}
.fi-rs-dot-circle:before {
  content: "\f41f";
}
.fi-rs-down-left-and-up-right-to-center:before {
  content: "\f420";
}
.fi-rs-down-left:before {
  content: "\f421";
}
.fi-rs-down-right:before {
  content: "\f422";
}
.fi-rs-down-to-line:before {
  content: "\f423";
}
.fi-rs-down:before {
  content: "\f424";
}
.fi-rs-download:before {
  content: "\f425";
}
.fi-rs-drafting-compass:before {
  content: "\f426";
}
.fi-rs-draw-polygon:before {
  content: "\f427";
}
.fi-rs-draw-square:before {
  content: "\f428";
}
.fi-rs-dreidel:before {
  content: "\f429";
}
.fi-rs-drink-alt:before {
  content: "\f42a";
}
.fi-rs-drone-front:before {
  content: "\f42b";
}
.fi-rs-drum-steelpan:before {
  content: "\f42c";
}
.fi-rs-drum:before {
  content: "\f42d";
}
.fi-rs-drumstick-bite:before {
  content: "\f42e";
}
.fi-rs-drumstick:before {
  content: "\f42f";
}
.fi-rs-dungeon:before {
  content: "\f430";
}
.fi-rs-duplicate:before {
  content: "\f431";
}
.fi-rs-e-learning:before {
  content: "\f432";
}
.fi-rs-e:before {
  content: "\f433";
}
.fi-rs-ear-deaf:before {
  content: "\f434";
}
.fi-rs-ear-muffs:before {
  content: "\f435";
}
.fi-rs-ear:before {
  content: "\f436";
}
.fi-rs-earnings:before {
  content: "\f437";
}
.fi-rs-earth-africa:before {
  content: "\f438";
}
.fi-rs-earth-americas:before {
  content: "\f439";
}
.fi-rs-earth-asia:before {
  content: "\f43a";
}
.fi-rs-earth-europa:before {
  content: "\f43b";
}
.fi-rs-eclipse-alt:before {
  content: "\f43c";
}
.fi-rs-eclipse:before {
  content: "\f43d";
}
.fi-rs-edit-alt:before {
  content: "\f43e";
}
.fi-rs-edit:before {
  content: "\f43f";
}
.fi-rs-egg-fried:before {
  content: "\f440";
}
.fi-rs-egg:before {
  content: "\f441";
}
.fi-rs-eject:before {
  content: "\f442";
}
.fi-rs-elevator:before {
  content: "\f443";
}
.fi-rs-engine-warning:before {
  content: "\f444";
}
.fi-rs-enter:before {
  content: "\f445";
}
.fi-rs-envelope-ban:before {
  content: "\f446";
}
.fi-rs-envelope-bulk:before {
  content: "\f447";
}
.fi-rs-envelope-dot:before {
  content: "\f448";
}
.fi-rs-envelope-download:before {
  content: "\f449";
}
.fi-rs-envelope-marker:before {
  content: "\f44a";
}
.fi-rs-envelope-open-dollar:before {
  content: "\f44b";
}
.fi-rs-envelope-open-text:before {
  content: "\f44c";
}
.fi-rs-envelope-open:before {
  content: "\f44d";
}
.fi-rs-envelope-plus:before {
  content: "\f44e";
}
.fi-rs-envelope:before {
  content: "\f44f";
}
.fi-rs-envelopes:before {
  content: "\f450";
}
.fi-rs-equality:before {
  content: "\f451";
}
.fi-rs-eraser:before {
  content: "\f452";
}
.fi-rs-escalator:before {
  content: "\f453";
}
.fi-rs-euro:before {
  content: "\f454";
}
.fi-rs-exchange-alt:before {
  content: "\f455";
}
.fi-rs-exchange:before {
  content: "\f456";
}
.fi-rs-exclamation:before {
  content: "\f457";
}
.fi-rs-exit:before {
  content: "\f458";
}
.fi-rs-expand-arrows-alt:before {
  content: "\f459";
}
.fi-rs-expand-arrows:before {
  content: "\f45a";
}
.fi-rs-expand:before {
  content: "\f45b";
}
.fi-rs-eye-dropper-half:before {
  content: "\f45c";
}
.fi-rs-eye-dropper:before {
  content: "\f45d";
}
.fi-rs-eye:before {
  content: "\f45e";
}
.fi-rs-eyes:before {
  content: "\f45f";
}
.fi-rs-f:before {
  content: "\f460";
}
.fi-rs-face-angry-horns:before {
  content: "\f461";
}
.fi-rs-face-anguished:before {
  content: "\f462";
}
.fi-rs-face-anxious-sweat:before {
  content: "\f463";
}
.fi-rs-face-astonished:before {
  content: "\f464";
}
.fi-rs-face-awesome:before {
  content: "\f465";
}
.fi-rs-face-beam-hand-over-mouth:before {
  content: "\f466";
}
.fi-rs-face-confounded:before {
  content: "\f467";
}
.fi-rs-face-confused:before {
  content: "\f468";
}
.fi-rs-face-cowboy-hat:before {
  content: "\f469";
}
.fi-rs-face-disappointed:before {
  content: "\f46a";
}
.fi-rs-face-disguise:before {
  content: "\f46b";
}
.fi-rs-face-downcast-sweat:before {
  content: "\f46c";
}
.fi-rs-face-drooling:before {
  content: "\f46d";
}
.fi-rs-face-explode:before {
  content: "\f46e";
}
.fi-rs-face-expressionless:before {
  content: "\f46f";
}
.fi-rs-face-eyes-xmarks:before {
  content: "\f470";
}
.fi-rs-face-fearful:before {
  content: "\f471";
}
.fi-rs-face-glasses:before {
  content: "\f472";
}
.fi-rs-face-grin-tongue-wink:before {
  content: "\f473";
}
.fi-rs-face-hand-yawn:before {
  content: "\f474";
}
.fi-rs-face-head-bandage:before {
  content: "\f475";
}
.fi-rs-face-hushed:before {
  content: "\f476";
}
.fi-rs-face-icicles:before {
  content: "\f477";
}
.fi-rs-face-lying:before {
  content: "\f478";
}
.fi-rs-face-mask:before {
  content: "\f479";
}
.fi-rs-face-monocle:before {
  content: "\f47a";
}
.fi-rs-face-nauseated:before {
  content: "\f47b";
}
.fi-rs-face-nose-steam:before {
  content: "\f47c";
}
.fi-rs-face-party:before {
  content: "\f47d";
}
.fi-rs-face-pensive:before {
  content: "\f47e";
}
.fi-rs-face-persevering:before {
  content: "\f47f";
}
.fi-rs-face-pleading:before {
  content: "\f480";
}
.fi-rs-face-raised-eyebrow:before {
  content: "\f481";
}
.fi-rs-face-relieved:before {
  content: "\f482";
}
.fi-rs-face-sad-sweat:before {
  content: "\f483";
}
.fi-rs-face-scream:before {
  content: "\f484";
}
.fi-rs-face-shush:before {
  content: "\f485";
}
.fi-rs-face-sleeping:before {
  content: "\f486";
}
.fi-rs-face-sleepy:before {
  content: "\f487";
}
.fi-rs-face-smile-halo:before {
  content: "\f488";
}
.fi-rs-face-smile-hearts:before {
  content: "\f489";
}
.fi-rs-face-smile-horns:before {
  content: "\f48a";
}
.fi-rs-face-smile-tear:before {
  content: "\f48b";
}
.fi-rs-face-smile-tongue:before {
  content: "\f48c";
}
.fi-rs-face-smile-upside-down:before {
  content: "\f48d";
}
.fi-rs-face-smiling-hands:before {
  content: "\f48e";
}
.fi-rs-face-smirking:before {
  content: "\f48f";
}
.fi-rs-face-sunglasses:before {
  content: "\f490";
}
.fi-rs-face-swear:before {
  content: "\f491";
}
.fi-rs-face-thermometer:before {
  content: "\f492";
}
.fi-rs-face-thinking:before {
  content: "\f493";
}
.fi-rs-face-tissue:before {
  content: "\f494";
}
.fi-rs-face-tongue-money:before {
  content: "\f495";
}
.fi-rs-face-tongue-sweat:before {
  content: "\f496";
}
.fi-rs-face-unamused:before {
  content: "\f497";
}
.fi-rs-face-viewfinder:before {
  content: "\f498";
}
.fi-rs-face-vomit:before {
  content: "\f499";
}
.fi-rs-face-weary:before {
  content: "\f49a";
}
.fi-rs-face-woozy:before {
  content: "\f49b";
}
.fi-rs-face-worried:before {
  content: "\f49c";
}
.fi-rs-face-zany:before {
  content: "\f49d";
}
.fi-rs-face-zipper:before {
  content: "\f49e";
}
.fi-rs-farm:before {
  content: "\f49f";
}
.fi-rs-fax:before {
  content: "\f4a0";
}
.fi-rs-feather-pointed:before {
  content: "\f4a1";
}
.fi-rs-feather:before {
  content: "\f4a2";
}
.fi-rs-fence:before {
  content: "\f4a3";
}
.fi-rs-ferris-wheel:before {
  content: "\f4a4";
}
.fi-rs-field-hockey:before {
  content: "\f4a5";
}
.fi-rs-fighter-jet:before {
  content: "\f4a6";
}
.fi-rs-file-ai:before {
  content: "\f4a7";
}
.fi-rs-file-audio:before {
  content: "\f4a8";
}
.fi-rs-file-binary:before {
  content: "\f4a9";
}
.fi-rs-file-chart-line:before {
  content: "\f4aa";
}
.fi-rs-file-chart-pie:before {
  content: "\f4ab";
}
.fi-rs-file-circle-info:before {
  content: "\f4ac";
}
.fi-rs-file-code:before {
  content: "\f4ad";
}
.fi-rs-file-csv:before {
  content: "\f4ae";
}
.fi-rs-file-download:before {
  content: "\f4af";
}
.fi-rs-file-edit:before {
  content: "\f4b0";
}
.fi-rs-file-eps:before {
  content: "\f4b1";
}
.fi-rs-file-excel:before {
  content: "\f4b2";
}
.fi-rs-file-exclamation:before {
  content: "\f4b3";
}
.fi-rs-file-export:before {
  content: "\f4b4";
}
.fi-rs-file-image:before {
  content: "\f4b5";
}
.fi-rs-file-import:before {
  content: "\f4b6";
}
.fi-rs-file-invoice-dollar:before {
  content: "\f4b7";
}
.fi-rs-file-invoice:before {
  content: "\f4b8";
}
.fi-rs-file-medical-alt:before {
  content: "\f4b9";
}
.fi-rs-file-medical:before {
  content: "\f4ba";
}
.fi-rs-file-minus:before {
  content: "\f4bb";
}
.fi-rs-file-pdf:before {
  content: "\f4bc";
}
.fi-rs-file-powerpoint:before {
  content: "\f4bd";
}
.fi-rs-file-prescription:before {
  content: "\f4be";
}
.fi-rs-file-psd:before {
  content: "\f4bf";
}
.fi-rs-file-signature:before {
  content: "\f4c0";
}
.fi-rs-file-spreadsheet:before {
  content: "\f4c1";
}
.fi-rs-file-upload:before {
  content: "\f4c2";
}
.fi-rs-file-user:before {
  content: "\f4c3";
}
.fi-rs-file-video:before {
  content: "\f4c4";
}
.fi-rs-file-word:before {
  content: "\f4c5";
}
.fi-rs-file-zipper:before {
  content: "\f4c6";
}
.fi-rs-file:before {
  content: "\f4c7";
}
.fi-rs-files-medical:before {
  content: "\f4c8";
}
.fi-rs-fill:before {
  content: "\f4c9";
}
.fi-rs-film-canister:before {
  content: "\f4ca";
}
.fi-rs-film-slash:before {
  content: "\f4cb";
}
.fi-rs-film:before {
  content: "\f4cc";
}
.fi-rs-films:before {
  content: "\f4cd";
}
.fi-rs-filter-slash:before {
  content: "\f4ce";
}
.fi-rs-filter:before {
  content: "\f4cf";
}
.fi-rs-filters:before {
  content: "\f4d0";
}
.fi-rs-fingerprint:before {
  content: "\f4d1";
}
.fi-rs-fire-flame-curved:before {
  content: "\f4d2";
}
.fi-rs-fire-smoke:before {
  content: "\f4d3";
}
.fi-rs-fish-cooked:before {
  content: "\f4d4";
}
.fi-rs-fish:before {
  content: "\f4d5";
}
.fi-rs-fishing-rod:before {
  content: "\f4d6";
}
.fi-rs-flag-alt:before {
  content: "\f4d7";
}
.fi-rs-flag-checkered:before {
  content: "\f4d8";
}
.fi-rs-flag-usa:before {
  content: "\f4d9";
}
.fi-rs-flag:before {
  content: "\f4da";
}
.fi-rs-flame:before {
  content: "\f4db";
}
.fi-rs-flask-poison:before {
  content: "\f4dc";
}
.fi-rs-flask-potion:before {
  content: "\f4dd";
}
.fi-rs-flip-horizontal:before {
  content: "\f4de";
}
.fi-rs-floppy-disk-circle-arrow-right:before {
  content: "\f4df";
}
.fi-rs-floppy-disk-circle-xmark:before {
  content: "\f4e0";
}
.fi-rs-florin-sign:before {
  content: "\f4e1";
}
.fi-rs-flower-bouquet:before {
  content: "\f4e2";
}
.fi-rs-flower-daffodil:before {
  content: "\f4e3";
}
.fi-rs-flower-tulip:before {
  content: "\f4e4";
}
.fi-rs-flower:before {
  content: "\f4e5";
}
.fi-rs-flushed:before {
  content: "\f4e6";
}
.fi-rs-flute:before {
  content: "\f4e7";
}
.fi-rs-flux-capacitor:before {
  content: "\f4e8";
}
.fi-rs-fog:before {
  content: "\f4e9";
}
.fi-rs-folder-download:before {
  content: "\f4ea";
}
.fi-rs-folder-minus:before {
  content: "\f4eb";
}
.fi-rs-folder-open:before {
  content: "\f4ec";
}
.fi-rs-folder-times:before {
  content: "\f4ed";
}
.fi-rs-folder-tree:before {
  content: "\f4ee";
}
.fi-rs-folder-upload:before {
  content: "\f4ef";
}
.fi-rs-folder-xmark:before {
  content: "\f4f0";
}
.fi-rs-folder:before {
  content: "\f4f1";
}
.fi-rs-folders:before {
  content: "\f4f2";
}
.fi-rs-follow-folder:before {
  content: "\f4f3";
}
.fi-rs-followcollection:before {
  content: "\f4f4";
}
.fi-rs-following:before {
  content: "\f4f5";
}
.fi-rs-football:before {
  content: "\f4f6";
}
.fi-rs-fork:before {
  content: "\f4f7";
}
.fi-rs-form:before {
  content: "\f4f8";
}
.fi-rs-fort:before {
  content: "\f4f9";
}
.fi-rs-forward-fast:before {
  content: "\f4fa";
}
.fi-rs-forward:before {
  content: "\f4fb";
}
.fi-rs-fox:before {
  content: "\f4fc";
}
.fi-rs-franc-sign:before {
  content: "\f4fd";
}
.fi-rs-french-fries:before {
  content: "\f4fe";
}
.fi-rs-frown:before {
  content: "\f4ff";
}
.fi-rs-ftp:before {
  content: "\f500";
}
.fi-rs-funnel-dollar:before {
  content: "\f501";
}
.fi-rs-g:before {
  content: "\f502";
}
.fi-rs-galaxy:before {
  content: "\f503";
}
.fi-rs-gallery-thumbnails:before {
  content: "\f504";
}
.fi-rs-gallery:before {
  content: "\f505";
}
.fi-rs-game-board-alt:before {
  content: "\f506";
}
.fi-rs-gamepad:before {
  content: "\f507";
}
.fi-rs-garage-car:before {
  content: "\f508";
}
.fi-rs-garage-open:before {
  content: "\f509";
}
.fi-rs-garage:before {
  content: "\f50a";
}
.fi-rs-garlic:before {
  content: "\f50b";
}
.fi-rs-gas-pump-alt:before {
  content: "\f50c";
}
.fi-rs-gas-pump-slash:before {
  content: "\f50d";
}
.fi-rs-gas-pump:before {
  content: "\f50e";
}
.fi-rs-gavel:before {
  content: "\f50f";
}
.fi-rs-gears:before {
  content: "\f510";
}
.fi-rs-gem:before {
  content: "\f511";
}
.fi-rs-ghost:before {
  content: "\f512";
}
.fi-rs-gif-square:before {
  content: "\f513";
}
.fi-rs-gif:before {
  content: "\f514";
}
.fi-rs-gift-card:before {
  content: "\f515";
}
.fi-rs-gift:before {
  content: "\f516";
}
.fi-rs-gifts:before {
  content: "\f517";
}
.fi-rs-gingerbread-man:before {
  content: "\f518";
}
.fi-rs-glass-champagne:before {
  content: "\f519";
}
.fi-rs-glass-cheers:before {
  content: "\f51a";
}
.fi-rs-glass-citrus:before {
  content: "\f51b";
}
.fi-rs-glass-whiskey-rocks:before {
  content: "\f51c";
}
.fi-rs-glass-whiskey:before {
  content: "\f51d";
}
.fi-rs-glass:before {
  content: "\f51e";
}
.fi-rs-glasses:before {
  content: "\f51f";
}
.fi-rs-globe-alt:before {
  content: "\f520";
}
.fi-rs-globe-snow:before {
  content: "\f521";
}
.fi-rs-globe:before {
  content: "\f522";
}
.fi-rs-golf-ball:before {
  content: "\f523";
}
.fi-rs-golf-club:before {
  content: "\f524";
}
.fi-rs-golf:before {
  content: "\f525";
}
.fi-rs-graduation-cap:before {
  content: "\f526";
}
.fi-rs-gramophone:before {
  content: "\f527";
}
.fi-rs-grape:before {
  content: "\f528";
}
.fi-rs-graphic-style:before {
  content: "\f529";
}
.fi-rs-graphic-tablet:before {
  content: "\f52a";
}
.fi-rs-grid-alt:before {
  content: "\f52b";
}
.fi-rs-grid-dividers:before {
  content: "\f52c";
}
.fi-rs-grid:before {
  content: "\f52d";
}
.fi-rs-grill:before {
  content: "\f52e";
}
.fi-rs-grimace:before {
  content: "\f52f";
}
.fi-rs-grin-alt:before {
  content: "\f530";
}
.fi-rs-grin-beam-sweat:before {
  content: "\f531";
}
.fi-rs-grin-beam:before {
  content: "\f532";
}
.fi-rs-grin-hearts:before {
  content: "\f533";
}
.fi-rs-grin-squint-tears:before {
  content: "\f534";
}
.fi-rs-grin-squint:before {
  content: "\f535";
}
.fi-rs-grin-stars:before {
  content: "\f536";
}
.fi-rs-grin-tears:before {
  content: "\f537";
}
.fi-rs-grin-tongue-squint:before {
  content: "\f538";
}
.fi-rs-grin-tongue-wink:before {
  content: "\f539";
}
.fi-rs-grin-tongue:before {
  content: "\f53a";
}
.fi-rs-grin-wink:before {
  content: "\f53b";
}
.fi-rs-grin:before {
  content: "\f53c";
}
.fi-rs-grip-dots-vertical:before {
  content: "\f53d";
}
.fi-rs-grip-dots:before {
  content: "\f53e";
}
.fi-rs-grip-horizontal:before {
  content: "\f53f";
}
.fi-rs-grip-lines-vertical:before {
  content: "\f540";
}
.fi-rs-grip-lines:before {
  content: "\f541";
}
.fi-rs-grip-vertical:before {
  content: "\f542";
}
.fi-rs-guarani-sign:before {
  content: "\f543";
}
.fi-rs-guitar-electric:before {
  content: "\f544";
}
.fi-rs-guitar:before {
  content: "\f545";
}
.fi-rs-guitars:before {
  content: "\f546";
}
.fi-rs-gym:before {
  content: "\f547";
}
.fi-rs-h-square:before {
  content: "\f548";
}
.fi-rs-h:before {
  content: "\f549";
}
.fi-rs-h1:before {
  content: "\f54a";
}
.fi-rs-h2:before {
  content: "\f54b";
}
.fi-rs-h3:before {
  content: "\f54c";
}
.fi-rs-h4:before {
  content: "\f54d";
}
.fi-rs-hamburger-soda:before {
  content: "\f54e";
}
.fi-rs-hamburger:before {
  content: "\f54f";
}
.fi-rs-hammer-crash:before {
  content: "\f550";
}
.fi-rs-hammer-war:before {
  content: "\f551";
}
.fi-rs-hammer:before {
  content: "\f552";
}
.fi-rs-hand-back-fist:before {
  content: "\f553";
}
.fi-rs-hand-back-point-down:before {
  content: "\f554";
}
.fi-rs-hand-back-point-left:before {
  content: "\f555";
}
.fi-rs-hand-back-point-ribbon:before {
  content: "\f556";
}
.fi-rs-hand-back-point-right:before {
  content: "\f557";
}
.fi-rs-hand-dots:before {
  content: "\f558";
}
.fi-rs-hand-fingers-crossed:before {
  content: "\f559";
}
.fi-rs-hand-heart:before {
  content: "\f55a";
}
.fi-rs-hand-holding-box:before {
  content: "\f55b";
}
.fi-rs-hand-holding-heart:before {
  content: "\f55c";
}
.fi-rs-hand-holding-medical:before {
  content: "\f55d";
}
.fi-rs-hand-holding-seeding:before {
  content: "\f55e";
}
.fi-rs-hand-holding-skull:before {
  content: "\f55f";
}
.fi-rs-hand-holding-usd:before {
  content: "\f560";
}
.fi-rs-hand-holding-water:before {
  content: "\f561";
}
.fi-rs-hand-horns:before {
  content: "\f562";
}
.fi-rs-hand-lizard:before {
  content: "\f563";
}
.fi-rs-hand-love:before {
  content: "\f564";
}
.fi-rs-hand-middle-finger:before {
  content: "\f565";
}
.fi-rs-hand-paper:before {
  content: "\f566";
}
.fi-rs-hand-peace:before {
  content: "\f567";
}
.fi-rs-hand-point-ribbon:before {
  content: "\f568";
}
.fi-rs-hand-scissors:before {
  content: "\f569";
}
.fi-rs-hand-sparkles:before {
  content: "\f56a";
}
.fi-rs-hand-spock:before {
  content: "\f56b";
}
.fi-rs-hand-wave:before {
  content: "\f56c";
}
.fi-rs-hand:before {
  content: "\f56d";
}
.fi-rs-hands-bubbles:before {
  content: "\f56e";
}
.fi-rs-hands-clapping:before {
  content: "\f56f";
}
.fi-rs-hands-holding-diamond:before {
  content: "\f570";
}
.fi-rs-hands-holding:before {
  content: "\f571";
}
.fi-rs-hands-usd:before {
  content: "\f572";
}
.fi-rs-handshake-angle:before {
  content: "\f573";
}
.fi-rs-handshake-simple-slash:before {
  content: "\f574";
}
.fi-rs-handshake:before {
  content: "\f575";
}
.fi-rs-happy:before {
  content: "\f576";
}
.fi-rs-hastag:before {
  content: "\f577";
}
.fi-rs-hat-birthday:before {
  content: "\f578";
}
.fi-rs-hat-chef:before {
  content: "\f579";
}
.fi-rs-hat-santa:before {
  content: "\f57a";
}
.fi-rs-hat-witch:before {
  content: "\f57b";
}
.fi-rs-hat-wizard:before {
  content: "\f57c";
}
.fi-rs-hdd:before {
  content: "\f57d";
}
.fi-rs-head-side-brain:before {
  content: "\f57e";
}
.fi-rs-head-side-cough-slash:before {
  content: "\f57f";
}
.fi-rs-head-side-cough:before {
  content: "\f580";
}
.fi-rs-head-side-headphones:before {
  content: "\f581";
}
.fi-rs-head-side-heart:before {
  content: "\f582";
}
.fi-rs-head-side-mask:before {
  content: "\f583";
}
.fi-rs-head-side-medical:before {
  content: "\f584";
}
.fi-rs-head-side-thinking:before {
  content: "\f585";
}
.fi-rs-head-side-virus:before {
  content: "\f586";
}
.fi-rs-head-side:before {
  content: "\f587";
}
.fi-rs-head-vr:before {
  content: "\f588";
}
.fi-rs-heading:before {
  content: "\f589";
}
.fi-rs-headphones:before {
  content: "\f58a";
}
.fi-rs-headset:before {
  content: "\f58b";
}
.fi-rs-heart-arrow:before {
  content: "\f58c";
}
.fi-rs-heart-crack:before {
  content: "\f58d";
}
.fi-rs-heart-half-stroke:before {
  content: "\f58e";
}
.fi-rs-heart-half:before {
  content: "\f58f";
}
.fi-rs-heart-rate:before {
  content: "\f590";
}
.fi-rs-heart:before {
  content: "\f591";
}
.fi-rs-heat:before {
  content: "\f592";
}
.fi-rs-helicopter-side:before {
  content: "\f593";
}
.fi-rs-hexagon-check:before {
  content: "\f594";
}
.fi-rs-hexagon-exclamation:before {
  content: "\f595";
}
.fi-rs-hexagon:before {
  content: "\f596";
}
.fi-rs-high-definition:before {
  content: "\f597";
}
.fi-rs-highlighter-line:before {
  content: "\f598";
}
.fi-rs-highlighter:before {
  content: "\f599";
}
.fi-rs-hiking:before {
  content: "\f59a";
}
.fi-rs-hockey-mask:before {
  content: "\f59b";
}
.fi-rs-hockey-puck:before {
  content: "\f59c";
}
.fi-rs-hockey-sticks:before {
  content: "\f59d";
}
.fi-rs-holly-berry:before {
  content: "\f59e";
}
.fi-rs-home-heart:before {
  content: "\f59f";
}
.fi-rs-home-location-alt:before {
  content: "\f5a0";
}
.fi-rs-home-location:before {
  content: "\f5a1";
}
.fi-rs-home:before {
  content: "\f5a2";
}
.fi-rs-hood-cloak:before {
  content: "\f5a3";
}
.fi-rs-horizontal-rule:before {
  content: "\f5a4";
}
.fi-rs-hospital-symbol:before {
  content: "\f5a5";
}
.fi-rs-hospital-user:before {
  content: "\f5a6";
}
.fi-rs-hospital:before {
  content: "\f5a7";
}
.fi-rs-hospitals:before {
  content: "\f5a8";
}
.fi-rs-hot-tub:before {
  content: "\f5a9";
}
.fi-rs-hotdog:before {
  content: "\f5aa";
}
.fi-rs-hotel:before {
  content: "\f5ab";
}
.fi-rs-hourglass-end:before {
  content: "\f5ac";
}
.fi-rs-hourglass-start:before {
  content: "\f5ad";
}
.fi-rs-hourglass:before {
  content: "\f5ae";
}
.fi-rs-house-blank:before {
  content: "\f5af";
}
.fi-rs-house-building:before {
  content: "\f5b0";
}
.fi-rs-house-chimney-blank:before {
  content: "\f5b1";
}
.fi-rs-house-chimney-crack:before {
  content: "\f5b2";
}
.fi-rs-house-chimney-medical:before {
  content: "\f5b3";
}
.fi-rs-house-chimney-window:before {
  content: "\f5b4";
}
.fi-rs-house-chimney:before {
  content: "\f5b5";
}
.fi-rs-house-crack:before {
  content: "\f5b6";
}
.fi-rs-house-flood:before {
  content: "\f5b7";
}
.fi-rs-house-laptop:before {
  content: "\f5b8";
}
.fi-rs-house-medical:before {
  content: "\f5b9";
}
.fi-rs-house-tree:before {
  content: "\f5ba";
}
.fi-rs-house-turret:before {
  content: "\f5bb";
}
.fi-rs-house-window:before {
  content: "\f5bc";
}
.fi-rs-hryvnia:before {
  content: "\f5bd";
}
.fi-rs-humidity:before {
  content: "\f5be";
}
.fi-rs-hundred-points:before {
  content: "\f5bf";
}
.fi-rs-hurricane:before {
  content: "\f5c0";
}
.fi-rs-i:before {
  content: "\f5c1";
}
.fi-rs-ice-cream:before {
  content: "\f5c2";
}
.fi-rs-ice-skate:before {
  content: "\f5c3";
}
.fi-rs-icicles:before {
  content: "\f5c4";
}
.fi-rs-icon-star:before {
  content: "\f5c5";
}
.fi-rs-id-badge:before {
  content: "\f5c6";
}
.fi-rs-id-card-clip-alt:before {
  content: "\f5c7";
}
.fi-rs-igloo:before {
  content: "\f5c8";
}
.fi-rs-image-slash:before {
  content: "\f5c9";
}
.fi-rs-images-user:before {
  content: "\f5ca";
}
.fi-rs-images:before {
  content: "\f5cb";
}
.fi-rs-inbox-in:before {
  content: "\f5cc";
}
.fi-rs-inbox-out:before {
  content: "\f5cd";
}
.fi-rs-inbox:before {
  content: "\f5ce";
}
.fi-rs-inboxes:before {
  content: "\f5cf";
}
.fi-rs-incognito:before {
  content: "\f5d0";
}
.fi-rs-indent:before {
  content: "\f5d1";
}
.fi-rs-indian-rupee-sign:before {
  content: "\f5d2";
}
.fi-rs-industry-windows:before {
  content: "\f5d3";
}
.fi-rs-infinity:before {
  content: "\f5d4";
}
.fi-rs-info:before {
  content: "\f5d5";
}
.fi-rs-information:before {
  content: "\f5d6";
}
.fi-rs-inhaler:before {
  content: "\f5d7";
}
.fi-rs-input-numeric:before {
  content: "\f5d8";
}
.fi-rs-input-pipe:before {
  content: "\f5d9";
}
.fi-rs-input-text:before {
  content: "\f5da";
}
.fi-rs-interactive:before {
  content: "\f5db";
}
.fi-rs-interlining:before {
  content: "\f5dc";
}
.fi-rs-interrogation:before {
  content: "\f5dd";
}
.fi-rs-island-tropical:before {
  content: "\f5de";
}
.fi-rs-italian-lira-sign:before {
  content: "\f5df";
}
.fi-rs-italic:before {
  content: "\f5e0";
}
.fi-rs-j:before {
  content: "\f5e1";
}
.fi-rs-jam:before {
  content: "\f5e2";
}
.fi-rs-joint:before {
  content: "\f5e3";
}
.fi-rs-joystick:before {
  content: "\f5e4";
}
.fi-rs-jpg:before {
  content: "\f5e5";
}
.fi-rs-jug:before {
  content: "\f5e6";
}
.fi-rs-k:before {
  content: "\f5e7";
}
.fi-rs-kazoo:before {
  content: "\f5e8";
}
.fi-rs-kerning:before {
  content: "\f5e9";
}
.fi-rs-key-skeleton-left-right:before {
  content: "\f5ea";
}
.fi-rs-key:before {
  content: "\f5eb";
}
.fi-rs-keyboard-brightness-low:before {
  content: "\f5ec";
}
.fi-rs-keyboard-brightness:before {
  content: "\f5ed";
}
.fi-rs-keyboard:before {
  content: "\f5ee";
}
.fi-rs-keynote:before {
  content: "\f5ef";
}
.fi-rs-kidneys:before {
  content: "\f5f0";
}
.fi-rs-kip-sign:before {
  content: "\f5f1";
}
.fi-rs-kiss-beam:before {
  content: "\f5f2";
}
.fi-rs-kiss-wink-heart:before {
  content: "\f5f3";
}
.fi-rs-kiss:before {
  content: "\f5f4";
}
.fi-rs-kite:before {
  content: "\f5f5";
}
.fi-rs-knife-kitchen:before {
  content: "\f5f6";
}
.fi-rs-knife:before {
  content: "\f5f7";
}
.fi-rs-l:before {
  content: "\f5f8";
}
.fi-rs-label:before {
  content: "\f5f9";
}
.fi-rs-landmark-alt:before {
  content: "\f5fa";
}
.fi-rs-language:before {
  content: "\f5fb";
}
.fi-rs-laptop-code:before {
  content: "\f5fc";
}
.fi-rs-laptop-medical:before {
  content: "\f5fd";
}
.fi-rs-laptop-mobile:before {
  content: "\f5fe";
}
.fi-rs-laptop:before {
  content: "\f5ff";
}
.fi-rs-lari-sign:before {
  content: "\f600";
}
.fi-rs-lasso-sparkles:before {
  content: "\f601";
}
.fi-rs-lasso:before {
  content: "\f602";
}
.fi-rs-laugh-beam:before {
  content: "\f603";
}
.fi-rs-laugh-squint:before {
  content: "\f604";
}
.fi-rs-laugh-wink:before {
  content: "\f605";
}
.fi-rs-laugh:before {
  content: "\f606";
}
.fi-rs-layer-minus:before {
  content: "\f607";
}
.fi-rs-layer-plus:before {
  content: "\f608";
}
.fi-rs-layers:before {
  content: "\f609";
}
.fi-rs-leaf-heart:before {
  content: "\f60a";
}
.fi-rs-leaf-maple:before {
  content: "\f60b";
}
.fi-rs-leaf-oak:before {
  content: "\f60c";
}
.fi-rs-leaf:before {
  content: "\f60d";
}
.fi-rs-left:before {
  content: "\f60e";
}
.fi-rs-lemon:before {
  content: "\f60f";
}
.fi-rs-letter-case:before {
  content: "\f610";
}
.fi-rs-lettuce:before {
  content: "\f611";
}
.fi-rs-level-down-alt:before {
  content: "\f612";
}
.fi-rs-level-down:before {
  content: "\f613";
}
.fi-rs-level-up-alt:before {
  content: "\f614";
}
.fi-rs-level-up:before {
  content: "\f615";
}
.fi-rs-license:before {
  content: "\f616";
}
.fi-rs-life-ring:before {
  content: "\f617";
}
.fi-rs-light-emergency-on:before {
  content: "\f618";
}
.fi-rs-light-emergency:before {
  content: "\f619";
}
.fi-rs-light-switch-off:before {
  content: "\f61a";
}
.fi-rs-light-switch-on:before {
  content: "\f61b";
}
.fi-rs-light-switch:before {
  content: "\f61c";
}
.fi-rs-lightbulb-dollar:before {
  content: "\f61d";
}
.fi-rs-lightbulb-exclamation:before {
  content: "\f61e";
}
.fi-rs-lightbulb-question:before {
  content: "\f61f";
}
.fi-rs-lightbulb-slash:before {
  content: "\f620";
}
.fi-rs-lights-holiday:before {
  content: "\f621";
}
.fi-rs-line-width:before {
  content: "\f622";
}
.fi-rs-link-alt:before {
  content: "\f623";
}
.fi-rs-link-horizontal-slash:before {
  content: "\f624";
}
.fi-rs-link-horizontal:before {
  content: "\f625";
}
.fi-rs-link-slash-alt:before {
  content: "\f626";
}
.fi-rs-link-slash:before {
  content: "\f627";
}
.fi-rs-link:before {
  content: "\f628";
}
.fi-rs-lips:before {
  content: "\f629";
}
.fi-rs-lipstick:before {
  content: "\f62a";
}
.fi-rs-lira-sign:before {
  content: "\f62b";
}
.fi-rs-list-check:before {
  content: "\f62c";
}
.fi-rs-list-dropdown:before {
  content: "\f62d";
}
.fi-rs-list-music:before {
  content: "\f62e";
}
.fi-rs-list-timeline:before {
  content: "\f62f";
}
.fi-rs-list:before {
  content: "\f630";
}
.fi-rs-litecoin-sign:before {
  content: "\f631";
}
.fi-rs-loading:before {
  content: "\f632";
}
.fi-rs-location-alt:before {
  content: "\f633";
}
.fi-rs-location-crosshairs-slash:before {
  content: "\f634";
}
.fi-rs-location-crosshairs:before {
  content: "\f635";
}
.fi-rs-location-dot-slash:before {
  content: "\f636";
}
.fi-rs-location-exclamation:before {
  content: "\f637";
}
.fi-rs-lock-alt:before {
  content: "\f638";
}
.fi-rs-lock:before {
  content: "\f639";
}
.fi-rs-log-out:before {
  content: "\f63a";
}
.fi-rs-low-vision:before {
  content: "\f63b";
}
.fi-rs-luchador:before {
  content: "\f63c";
}
.fi-rs-luggage-cart:before {
  content: "\f63d";
}
.fi-rs-luggage-rolling:before {
  content: "\f63e";
}
.fi-rs-lungs-virus:before {
  content: "\f63f";
}
.fi-rs-lungs:before {
  content: "\f640";
}
.fi-rs-m:before {
  content: "\f641";
}
.fi-rs-magic-wand:before {
  content: "\f642";
}
.fi-rs-mailbox:before {
  content: "\f643";
}
.fi-rs-makeup-brush:before {
  content: "\f644";
}
.fi-rs-man-head:before {
  content: "\f645";
}
.fi-rs-manat-sign:before {
  content: "\f646";
}
.fi-rs-mandolin:before {
  content: "\f647";
}
.fi-rs-map-marker-check:before {
  content: "\f648";
}
.fi-rs-map-marker-cross:before {
  content: "\f649";
}
.fi-rs-map-marker-edit:before {
  content: "\f64a";
}
.fi-rs-map-marker-home:before {
  content: "\f64b";
}
.fi-rs-map-marker-minus:before {
  content: "\f64c";
}
.fi-rs-map-marker-plus:before {
  content: "\f64d";
}
.fi-rs-map-marker-question:before {
  content: "\f64e";
}
.fi-rs-map-marker-slash:before {
  content: "\f64f";
}
.fi-rs-map-marker-smile:before {
  content: "\f650";
}
.fi-rs-map-marker:before {
  content: "\f651";
}
.fi-rs-map-pin:before {
  content: "\f652";
}
.fi-rs-map:before {
  content: "\f653";
}
.fi-rs-marker-time:before {
  content: "\f654";
}
.fi-rs-marker:before {
  content: "\f655";
}
.fi-rs-mars-double:before {
  content: "\f656";
}
.fi-rs-mars-stroke-right:before {
  content: "\f657";
}
.fi-rs-mars-stroke-up:before {
  content: "\f658";
}
.fi-rs-mars:before {
  content: "\f659";
}
.fi-rs-mask-carnival:before {
  content: "\f65a";
}
.fi-rs-mask-face:before {
  content: "\f65b";
}
.fi-rs-meat:before {
  content: "\f65c";
}
.fi-rs-medical-star:before {
  content: "\f65d";
}
.fi-rs-medicine:before {
  content: "\f65e";
}
.fi-rs-megaphone:before {
  content: "\f65f";
}
.fi-rs-meh-blank:before {
  content: "\f660";
}
.fi-rs-meh-rolling-eyes:before {
  content: "\f661";
}
.fi-rs-meh:before {
  content: "\f662";
}
.fi-rs-melon:before {
  content: "\f663";
}
.fi-rs-memo-circle-check:before {
  content: "\f664";
}
.fi-rs-memo-pad:before {
  content: "\f665";
}
.fi-rs-memo:before {
  content: "\f666";
}
.fi-rs-memory:before {
  content: "\f667";
}
.fi-rs-menu-dots-vertical:before {
  content: "\f668";
}
.fi-rs-menu-dots:before {
  content: "\f669";
}
.fi-rs-mercury:before {
  content: "\f66a";
}
.fi-rs-message-arrow-down:before {
  content: "\f66b";
}
.fi-rs-message-arrow-up-right:before {
  content: "\f66c";
}
.fi-rs-message-arrow-up:before {
  content: "\f66d";
}
.fi-rs-message-bot:before {
  content: "\f66e";
}
.fi-rs-message-code:before {
  content: "\f66f";
}
.fi-rs-message-dollar:before {
  content: "\f670";
}
.fi-rs-message-image:before {
  content: "\f671";
}
.fi-rs-message-question:before {
  content: "\f672";
}
.fi-rs-message-quote:before {
  content: "\f673";
}
.fi-rs-message-slash:before {
  content: "\f674";
}
.fi-rs-message-sms:before {
  content: "\f675";
}
.fi-rs-message-text:before {
  content: "\f676";
}
.fi-rs-message-xmark:before {
  content: "\f677";
}
.fi-rs-messages-dollar:before {
  content: "\f678";
}
.fi-rs-messages-question:before {
  content: "\f679";
}
.fi-rs-messages:before {
  content: "\f67a";
}
.fi-rs-meteor:before {
  content: "\f67b";
}
.fi-rs-microchip:before {
  content: "\f67c";
}
.fi-rs-microphone-alt:before {
  content: "\f67d";
}
.fi-rs-microphone-slash:before {
  content: "\f67e";
}
.fi-rs-microphone:before {
  content: "\f67f";
}
.fi-rs-microscope:before {
  content: "\f680";
}
.fi-rs-mill-sign:before {
  content: "\f681";
}
.fi-rs-mind-share:before {
  content: "\f682";
}
.fi-rs-minus-circle:before {
  content: "\f683";
}
.fi-rs-minus-hexagon:before {
  content: "\f684";
}
.fi-rs-minus-small:before {
  content: "\f685";
}
.fi-rs-minuss:before {
  content: "\f686";
}
.fi-rs-mistletoe:before {
  content: "\f687";
}
.fi-rs-mobile-button:before {
  content: "\f688";
}
.fi-rs-mobile-notch:before {
  content: "\f689";
}
.fi-rs-mobile:before {
  content: "\f68a";
}
.fi-rs-mockup:before {
  content: "\f68b";
}
.fi-rs-mode-landscape:before {
  content: "\f68c";
}
.fi-rs-mode-portrait:before {
  content: "\f68d";
}
.fi-rs-money-bill-simple:before {
  content: "\f68e";
}
.fi-rs-money-bill-wave-alt:before {
  content: "\f68f";
}
.fi-rs-money-bill-wave:before {
  content: "\f690";
}
.fi-rs-money-bills-simple:before {
  content: "\f691";
}
.fi-rs-money-bills:before {
  content: "\f692";
}
.fi-rs-money-check-edit-alt:before {
  content: "\f693";
}
.fi-rs-money-check-edit:before {
  content: "\f694";
}
.fi-rs-money-check:before {
  content: "\f695";
}
.fi-rs-money-from-bracket:before {
  content: "\f696";
}
.fi-rs-money-simple-from-bracket:before {
  content: "\f697";
}
.fi-rs-money:before {
  content: "\f698";
}
.fi-rs-monument:before {
  content: "\f699";
}
.fi-rs-moon-stars:before {
  content: "\f69a";
}
.fi-rs-moon:before {
  content: "\f69b";
}
.fi-rs-mortar-pestle:before {
  content: "\f69c";
}
.fi-rs-motorcycle:before {
  content: "\f69d";
}
.fi-rs-mountain:before {
  content: "\f69e";
}
.fi-rs-mountains:before {
  content: "\f69f";
}
.fi-rs-mouse:before {
  content: "\f6a0";
}
.fi-rs-move-to-folder-2:before {
  content: "\f6a1";
}
.fi-rs-move-to-folder:before {
  content: "\f6a2";
}
.fi-rs-mp3-player:before {
  content: "\f6a3";
}
.fi-rs-mug-alt:before {
  content: "\f6a4";
}
.fi-rs-mug-hot-alt:before {
  content: "\f6a5";
}
.fi-rs-mug-hot:before {
  content: "\f6a6";
}
.fi-rs-mug-marshmallows:before {
  content: "\f6a7";
}
.fi-rs-mug-tea:before {
  content: "\f6a8";
}
.fi-rs-mug:before {
  content: "\f6a9";
}
.fi-rs-mushroom:before {
  content: "\f6aa";
}
.fi-rs-music-alt:before {
  content: "\f6ab";
}
.fi-rs-music-file:before {
  content: "\f6ac";
}
.fi-rs-music-note-slash:before {
  content: "\f6ad";
}
.fi-rs-music-note:before {
  content: "\f6ae";
}
.fi-rs-music-slash:before {
  content: "\f6af";
}
.fi-rs-music:before {
  content: "\f6b0";
}
.fi-rs-n:before {
  content: "\f6b1";
}
.fi-rs-naira-sign:before {
  content: "\f6b2";
}
.fi-rs-navigation:before {
  content: "\f6b3";
}
.fi-rs-network-cloud:before {
  content: "\f6b4";
}
.fi-rs-network:before {
  content: "\f6b5";
}
.fi-rs-neuter:before {
  content: "\f6b6";
}
.fi-rs-nfc-lock:before {
  content: "\f6b7";
}
.fi-rs-nfc-magnifying-glass:before {
  content: "\f6b8";
}
.fi-rs-nfc-pen:before {
  content: "\f6b9";
}
.fi-rs-nfc-slash:before {
  content: "\f6ba";
}
.fi-rs-nfc-trash:before {
  content: "\f6bb";
}
.fi-rs-nfc:before {
  content: "\f6bc";
}
.fi-rs-no-people:before {
  content: "\f6bd";
}
.fi-rs-noodles:before {
  content: "\f6be";
}
.fi-rs-notdef:before {
  content: "\f6bf";
}
.fi-rs-note-medical:before {
  content: "\f6c0";
}
.fi-rs-note-sticky:before {
  content: "\f6c1";
}
.fi-rs-note:before {
  content: "\f6c2";
}
.fi-rs-notebook-alt:before {
  content: "\f6c3";
}
.fi-rs-notebook:before {
  content: "\f6c4";
}
.fi-rs-notes-medical:before {
  content: "\f6c5";
}
.fi-rs-notes:before {
  content: "\f6c6";
}
.fi-rs-o:before {
  content: "\f6c7";
}
.fi-rs-object-exclude:before {
  content: "\f6c8";
}
.fi-rs-object-group:before {
  content: "\f6c9";
}
.fi-rs-object-intersect:before {
  content: "\f6ca";
}
.fi-rs-object-subtract:before {
  content: "\f6cb";
}
.fi-rs-object-ungroup:before {
  content: "\f6cc";
}
.fi-rs-object-union:before {
  content: "\f6cd";
}
.fi-rs-objects-column:before {
  content: "\f6ce";
}
.fi-rs-octagon:before {
  content: "\f6cf";
}
.fi-rs-oil-can:before {
  content: "\f6d0";
}
.fi-rs-oil-temp:before {
  content: "\f6d1";
}
.fi-rs-olive-oil:before {
  content: "\f6d2";
}
.fi-rs-olives:before {
  content: "\f6d3";
}
.fi-rs-onion:before {
  content: "\f6d4";
}
.fi-rs-opacity:before {
  content: "\f6d5";
}
.fi-rs-ornament:before {
  content: "\f6d6";
}
.fi-rs-outdent:before {
  content: "\f6d7";
}
.fi-rs-overline:before {
  content: "\f6d8";
}
.fi-rs-p:before {
  content: "\f6d9";
}
.fi-rs-package:before {
  content: "\f6da";
}
.fi-rs-page-break:before {
  content: "\f6db";
}
.fi-rs-pager:before {
  content: "\f6dc";
}
.fi-rs-paint-brush:before {
  content: "\f6dd";
}
.fi-rs-paint-roller:before {
  content: "\f6de";
}
.fi-rs-paintbrush-pencil:before {
  content: "\f6df";
}
.fi-rs-palette:before {
  content: "\f6e0";
}
.fi-rs-pan:before {
  content: "\f6e1";
}
.fi-rs-panorama:before {
  content: "\f6e2";
}
.fi-rs-paper-plane-top:before {
  content: "\f6e3";
}
.fi-rs-paper-plane:before {
  content: "\f6e4";
}
.fi-rs-paperclip-vertical:before {
  content: "\f6e5";
}
.fi-rs-parachute-box:before {
  content: "\f6e6";
}
.fi-rs-paragraph-left:before {
  content: "\f6e7";
}
.fi-rs-paragraph:before {
  content: "\f6e8";
}
.fi-rs-parking-circle-slash:before {
  content: "\f6e9";
}
.fi-rs-parking-circle:before {
  content: "\f6ea";
}
.fi-rs-parking-slash:before {
  content: "\f6eb";
}
.fi-rs-parking:before {
  content: "\f6ec";
}
.fi-rs-party-bell:before {
  content: "\f6ed";
}
.fi-rs-party-horn:before {
  content: "\f6ee";
}
.fi-rs-passport:before {
  content: "\f6ef";
}
.fi-rs-password:before {
  content: "\f6f0";
}
.fi-rs-paste:before {
  content: "\f6f1";
}
.fi-rs-pattern:before {
  content: "\f6f2";
}
.fi-rs-pause-circle:before {
  content: "\f6f3";
}
.fi-rs-pause:before {
  content: "\f6f4";
}
.fi-rs-paw:before {
  content: "\f6f5";
}
.fi-rs-peace:before {
  content: "\f6f6";
}
.fi-rs-peach:before {
  content: "\f6f7";
}
.fi-rs-pen-circle:before {
  content: "\f6f8";
}
.fi-rs-pen-clip-slash:before {
  content: "\f6f9";
}
.fi-rs-pen-clip:before {
  content: "\f6fa";
}
.fi-rs-pen-fancy-slash:before {
  content: "\f6fb";
}
.fi-rs-pen-fancy:before {
  content: "\f6fc";
}
.fi-rs-pen-field:before {
  content: "\f6fd";
}
.fi-rs-pen-nib-slash:before {
  content: "\f6fe";
}
.fi-rs-pen-nib:before {
  content: "\f6ff";
}
.fi-rs-pen-slash:before {
  content: "\f700";
}
.fi-rs-pen-square:before {
  content: "\f701";
}
.fi-rs-pen-swirl:before {
  content: "\f702";
}
.fi-rs-pencil-paintbrush:before {
  content: "\f703";
}
.fi-rs-pencil-ruler:before {
  content: "\f704";
}
.fi-rs-pencil-slash:before {
  content: "\f705";
}
.fi-rs-pencil:before {
  content: "\f706";
}
.fi-rs-pennant:before {
  content: "\f707";
}
.fi-rs-people-arrows-left-right:before {
  content: "\f708";
}
.fi-rs-people-carry-box:before {
  content: "\f709";
}
.fi-rs-people-dress:before {
  content: "\f70a";
}
.fi-rs-people-pants:before {
  content: "\f70b";
}
.fi-rs-people-poll:before {
  content: "\f70c";
}
.fi-rs-people:before {
  content: "\f70d";
}
.fi-rs-pepper-hot:before {
  content: "\f70e";
}
.fi-rs-pepper:before {
  content: "\f70f";
}
.fi-rs-percentage:before {
  content: "\f710";
}
.fi-rs-person-dolly-empty:before {
  content: "\f711";
}
.fi-rs-person-dolly:before {
  content: "\f712";
}
.fi-rs-person-dress-simple:before {
  content: "\f713";
}
.fi-rs-person-dress:before {
  content: "\f714";
}
.fi-rs-person-praying:before {
  content: "\f715";
}
.fi-rs-person-pregnant:before {
  content: "\f716";
}
.fi-rs-person-seat-reclined:before {
  content: "\f717";
}
.fi-rs-person-seat:before {
  content: "\f718";
}
.fi-rs-person-simple:before {
  content: "\f719";
}
.fi-rs-person-walking-with-cane:before {
  content: "\f71a";
}
.fi-rs-peseta-sign:before {
  content: "\f71b";
}
.fi-rs-peso-sign:before {
  content: "\f71c";
}
.fi-rs-pharmacy:before {
  content: "\f71d";
}
.fi-rs-phone-call:before {
  content: "\f71e";
}
.fi-rs-phone-cross:before {
  content: "\f71f";
}
.fi-rs-phone-flip:before {
  content: "\f720";
}
.fi-rs-phone-office:before {
  content: "\f721";
}
.fi-rs-phone-pause:before {
  content: "\f722";
}
.fi-rs-phone-plus:before {
  content: "\f723";
}
.fi-rs-phone-slash:before {
  content: "\f724";
}
.fi-rs-photo-film-music:before {
  content: "\f725";
}
.fi-rs-photo-video:before {
  content: "\f726";
}
.fi-rs-physics:before {
  content: "\f727";
}
.fi-rs-Pi:before {
  content: "\f728";
}
.fi-rs-piano-keyboard:before {
  content: "\f729";
}
.fi-rs-piano:before {
  content: "\f72a";
}
.fi-rs-picnic:before {
  content: "\f72b";
}
.fi-rs-picpeople-filled:before {
  content: "\f72c";
}
.fi-rs-picpeople:before {
  content: "\f72d";
}
.fi-rs-picture:before {
  content: "\f72e";
}
.fi-rs-pie:before {
  content: "\f72f";
}
.fi-rs-piggy-bank:before {
  content: "\f730";
}
.fi-rs-pills:before {
  content: "\f731";
}
.fi-rs-pineapple:before {
  content: "\f732";
}
.fi-rs-ping-pong:before {
  content: "\f733";
}
.fi-rs-pipe-smoking:before {
  content: "\f734";
}
.fi-rs-pixabay:before {
  content: "\f735";
}
.fi-rs-pizza-slice:before {
  content: "\f736";
}
.fi-rs-plane-alt:before {
  content: "\f737";
}
.fi-rs-plane-arrival:before {
  content: "\f738";
}
.fi-rs-plane-departure:before {
  content: "\f739";
}
.fi-rs-plane-prop:before {
  content: "\f73a";
}
.fi-rs-plane-tail:before {
  content: "\f73b";
}
.fi-rs-plane:before {
  content: "\f73c";
}
.fi-rs-planet-moon:before {
  content: "\f73d";
}
.fi-rs-planet-ringed:before {
  content: "\f73e";
}
.fi-rs-plate:before {
  content: "\f73f";
}
.fi-rs-play-alt:before {
  content: "\f740";
}
.fi-rs-play-circle:before {
  content: "\f741";
}
.fi-rs-play-pause:before {
  content: "\f742";
}
.fi-rs-play:before {
  content: "\f743";
}
.fi-rs-playing-cards:before {
  content: "\f744";
}
.fi-rs-plus-hexagon:before {
  content: "\f745";
}
.fi-rs-plus-minus:before {
  content: "\f746";
}
.fi-rs-plus-small:before {
  content: "\f747";
}
.fi-rs-plus:before {
  content: "\f748";
}
.fi-rs-podcast:before {
  content: "\f749";
}
.fi-rs-podium-star:before {
  content: "\f74a";
}
.fi-rs-podium:before {
  content: "\f74b";
}
.fi-rs-poker-chip:before {
  content: "\f74c";
}
.fi-rs-police-box:before {
  content: "\f74d";
}
.fi-rs-poll-h:before {
  content: "\f74e";
}
.fi-rs-poo-bolt:before {
  content: "\f74f";
}
.fi-rs-poo:before {
  content: "\f750";
}
.fi-rs-poop:before {
  content: "\f751";
}
.fi-rs-popcorn:before {
  content: "\f752";
}
.fi-rs-portal-enter:before {
  content: "\f753";
}
.fi-rs-portal-exit:before {
  content: "\f754";
}
.fi-rs-portrait:before {
  content: "\f755";
}
.fi-rs-pot:before {
  content: "\f756";
}
.fi-rs-pound:before {
  content: "\f757";
}
.fi-rs-prescription-bottle-alt:before {
  content: "\f758";
}
.fi-rs-prescription-bottle-pill:before {
  content: "\f759";
}
.fi-rs-prescription-bottle:before {
  content: "\f75a";
}
.fi-rs-prescription:before {
  content: "\f75b";
}
.fi-rs-presentation:before {
  content: "\f75c";
}
.fi-rs-print-magnifying-glass:before {
  content: "\f75d";
}
.fi-rs-print:before {
  content: "\f75e";
}
.fi-rs-procedures:before {
  content: "\f75f";
}
.fi-rs-projector:before {
  content: "\f760";
}
.fi-rs-protractor:before {
  content: "\f761";
}
.fi-rs-pulse:before {
  content: "\f762";
}
.fi-rs-pump-medical:before {
  content: "\f763";
}
.fi-rs-pumpkin-alt:before {
  content: "\f764";
}
.fi-rs-pumpkin:before {
  content: "\f765";
}
.fi-rs-puzzle-alt:before {
  content: "\f766";
}
.fi-rs-puzzle-piece:before {
  content: "\f767";
}
.fi-rs-pyramid:before {
  content: "\f768";
}
.fi-rs-q:before {
  content: "\f769";
}
.fi-rs-qrcode:before {
  content: "\f76a";
}
.fi-rs-question-square:before {
  content: "\f76b";
}
.fi-rs-question:before {
  content: "\f76c";
}
.fi-rs-quote-right:before {
  content: "\f76d";
}
.fi-rs-r:before {
  content: "\f76e";
}
.fi-rs-racquet:before {
  content: "\f76f";
}
.fi-rs-radar:before {
  content: "\f770";
}
.fi-rs-radiation-alt:before {
  content: "\f771";
}
.fi-rs-radiation:before {
  content: "\f772";
}
.fi-rs-radio-alt:before {
  content: "\f773";
}
.fi-rs-radio:before {
  content: "\f774";
}
.fi-rs-radish:before {
  content: "\f775";
}
.fi-rs-rainbow:before {
  content: "\f776";
}
.fi-rs-raindrops:before {
  content: "\f777";
}
.fi-rs-ramp-loading:before {
  content: "\f778";
}
.fi-rs-raygun:before {
  content: "\f779";
}
.fi-rs-rec:before {
  content: "\f77a";
}
.fi-rs-receipt:before {
  content: "\f77b";
}
.fi-rs-record-vinyl:before {
  content: "\f77c";
}
.fi-rs-rectabgle-vertical:before {
  content: "\f77d";
}
.fi-rs-rectangle-barcode:before {
  content: "\f77e";
}
.fi-rs-rectangle-code:before {
  content: "\f77f";
}
.fi-rs-rectangle-horizontal:before {
  content: "\f780";
}
.fi-rs-rectangle-list:before {
  content: "\f781";
}
.fi-rs-rectangle-panoramic:before {
  content: "\f782";
}
.fi-rs-rectangle-pro:before {
  content: "\f783";
}
.fi-rs-rectangle-vertical-history:before {
  content: "\f784";
}
.fi-rs-rectangle-vertical:before {
  content: "\f785";
}
.fi-rs-rectangle-xmark:before {
  content: "\f786";
}
.fi-rs-rectangles-mixed:before {
  content: "\f787";
}
.fi-rs-recycle:before {
  content: "\f788";
}
.fi-rs-redo-alt:before {
  content: "\f789";
}
.fi-rs-redo:before {
  content: "\f78a";
}
.fi-rs-reel:before {
  content: "\f78b";
}
.fi-rs-reflect-horizontal:before {
  content: "\f78c";
}
.fi-rs-reflect-vertical:before {
  content: "\f78d";
}
.fi-rs-reflect:before {
  content: "\f78e";
}
.fi-rs-refresh:before {
  content: "\f78f";
}
.fi-rs-registered:before {
  content: "\f790";
}
.fi-rs-remove-folder:before {
  content: "\f791";
}
.fi-rs-remove-user:before {
  content: "\f792";
}
.fi-rs-replace:before {
  content: "\f793";
}
.fi-rs-reply-all:before {
  content: "\f794";
}
.fi-rs-republican:before {
  content: "\f795";
}
.fi-rs-resize:before {
  content: "\f796";
}
.fi-rs-resources:before {
  content: "\f797";
}
.fi-rs-restaurant:before {
  content: "\f798";
}
.fi-rs-restroom-simple:before {
  content: "\f799";
}
.fi-rs-rewind:before {
  content: "\f79a";
}
.fi-rs-rhombus:before {
  content: "\f79b";
}
.fi-rs-ribbon:before {
  content: "\f79c";
}
.fi-rs-right:before {
  content: "\f79d";
}
.fi-rs-rings-wedding:before {
  content: "\f79e";
}
.fi-rs-road:before {
  content: "\f79f";
}
.fi-rs-rocket-lunch:before {
  content: "\f7a0";
}
.fi-rs-rocket:before {
  content: "\f7a1";
}
.fi-rs-roller-coaster:before {
  content: "\f7a2";
}
.fi-rs-room-service:before {
  content: "\f7a3";
}
.fi-rs-rotate-exclamation:before {
  content: "\f7a4";
}
.fi-rs-rotate-left:before {
  content: "\f7a5";
}
.fi-rs-rotate-right:before {
  content: "\f7a6";
}
.fi-rs-route-highway:before {
  content: "\f7a7";
}
.fi-rs-route-interstate:before {
  content: "\f7a8";
}
.fi-rs-route:before {
  content: "\f7a9";
}
.fi-rs-rss-alt:before {
  content: "\f7aa";
}
.fi-rs-rss:before {
  content: "\f7ab";
}
.fi-rs-ruble-sign:before {
  content: "\f7ac";
}
.fi-rs-rugby-helmet:before {
  content: "\f7ad";
}
.fi-rs-rugby:before {
  content: "\f7ae";
}
.fi-rs-ruler-combined:before {
  content: "\f7af";
}
.fi-rs-ruler-horizontal:before {
  content: "\f7b0";
}
.fi-rs-ruler-triangle:before {
  content: "\f7b1";
}
.fi-rs-ruler-vertical:before {
  content: "\f7b2";
}
.fi-rs-running:before {
  content: "\f7b3";
}
.fi-rs-rupee-sign:before {
  content: "\f7b4";
}
.fi-rs-rupiah-sign:before {
  content: "\f7b5";
}
.fi-rs-rv:before {
  content: "\f7b6";
}
.fi-rs-s:before {
  content: "\f7b7";
}
.fi-rs-sack-dollar:before {
  content: "\f7b8";
}
.fi-rs-sack:before {
  content: "\f7b9";
}
.fi-rs-sad-cry:before {
  content: "\f7ba";
}
.fi-rs-sad-tear:before {
  content: "\f7bb";
}
.fi-rs-sad:before {
  content: "\f7bc";
}
.fi-rs-sailboat:before {
  content: "\f7bd";
}
.fi-rs-salad:before {
  content: "\f7be";
}
.fi-rs-salt-pepper:before {
  content: "\f7bf";
}
.fi-rs-sandwich:before {
  content: "\f7c0";
}
.fi-rs-sauce:before {
  content: "\f7c1";
}
.fi-rs-sausage:before {
  content: "\f7c2";
}
.fi-rs-sax-hot:before {
  content: "\f7c3";
}
.fi-rs-saxophone:before {
  content: "\f7c4";
}
.fi-rs-scale:before {
  content: "\f7c5";
}
.fi-rs-scalpel-path:before {
  content: "\f7c6";
}
.fi-rs-scalpel:before {
  content: "\f7c7";
}
.fi-rs-scarecrow:before {
  content: "\f7c8";
}
.fi-rs-school-bus:before {
  content: "\f7c9";
}
.fi-rs-school:before {
  content: "\f7ca";
}
.fi-rs-scissors:before {
  content: "\f7cb";
}
.fi-rs-screen:before {
  content: "\f7cc";
}
.fi-rs-screencast:before {
  content: "\f7cd";
}
.fi-rs-scribble:before {
  content: "\f7ce";
}
.fi-rs-scroll-torah:before {
  content: "\f7cf";
}
.fi-rs-scrubber:before {
  content: "\f7d0";
}
.fi-rs-scythe:before {
  content: "\f7d1";
}
.fi-rs-seal-exclamation:before {
  content: "\f7d2";
}
.fi-rs-seal-question:before {
  content: "\f7d3";
}
.fi-rs-search-alt:before {
  content: "\f7d4";
}
.fi-rs-search-dollar:before {
  content: "\f7d5";
}
.fi-rs-search-heart:before {
  content: "\f7d6";
}
.fi-rs-search-location:before {
  content: "\f7d7";
}
.fi-rs-search:before {
  content: "\f7d8";
}
.fi-rs-seat-airline:before {
  content: "\f7d9";
}
.fi-rs-seedling:before {
  content: "\f7da";
}
.fi-rs-send-back:before {
  content: "\f7db";
}
.fi-rs-send-backward:before {
  content: "\f7dc";
}
.fi-rs-sensor-alert:before {
  content: "\f7dd";
}
.fi-rs-sensor-fire:before {
  content: "\f7de";
}
.fi-rs-sensor-on:before {
  content: "\f7df";
}
.fi-rs-sensor-smoke:before {
  content: "\f7e0";
}
.fi-rs-sensor:before {
  content: "\f7e1";
}
.fi-rs-settings-sliders:before {
  content: "\f7e2";
}
.fi-rs-settings:before {
  content: "\f7e3";
}
.fi-rs-share-alt-square:before {
  content: "\f7e4";
}
.fi-rs-share-square:before {
  content: "\f7e5";
}
.fi-rs-share:before {
  content: "\f7e6";
}
.fi-rs-shekel-sign:before {
  content: "\f7e7";
}
.fi-rs-shield-check:before {
  content: "\f7e8";
}
.fi-rs-shield-exclamation:before {
  content: "\f7e9";
}
.fi-rs-shield-interrogation:before {
  content: "\f7ea";
}
.fi-rs-shield-plus:before {
  content: "\f7eb";
}
.fi-rs-shield-virus:before {
  content: "\f7ec";
}
.fi-rs-shield:before {
  content: "\f7ed";
}
.fi-rs-ship-side:before {
  content: "\f7ee";
}
.fi-rs-ship:before {
  content: "\f7ef";
}
.fi-rs-shoe-prints:before {
  content: "\f7f0";
}
.fi-rs-shop-lock:before {
  content: "\f7f1";
}
.fi-rs-shop-slash:before {
  content: "\f7f2";
}
.fi-rs-shop:before {
  content: "\f7f3";
}
.fi-rs-shopping-bag-add:before {
  content: "\f7f4";
}
.fi-rs-shopping-bag:before {
  content: "\f7f5";
}
.fi-rs-shopping-basket:before {
  content: "\f7f6";
}
.fi-rs-shopping-cart-add:before {
  content: "\f7f7";
}
.fi-rs-shopping-cart-check:before {
  content: "\f7f8";
}
.fi-rs-shopping-cart:before {
  content: "\f7f9";
}
.fi-rs-shredder:before {
  content: "\f7fa";
}
.fi-rs-shrimp:before {
  content: "\f7fb";
}
.fi-rs-shuffle:before {
  content: "\f7fc";
}
.fi-rs-shuttle-van:before {
  content: "\f7fd";
}
.fi-rs-shuttlecock:before {
  content: "\f7fe";
}
.fi-rs-sidebar-flip:before {
  content: "\f7ff";
}
.fi-rs-sidebar:before {
  content: "\f800";
}
.fi-rs-sigma:before {
  content: "\f801";
}
.fi-rs-sign-hanging:before {
  content: "\f802";
}
.fi-rs-sign-in-alt:before {
  content: "\f803";
}
.fi-rs-sign-out-alt:before {
  content: "\f804";
}
.fi-rs-signal-alt-1:before {
  content: "\f805";
}
.fi-rs-signal-alt-2:before {
  content: "\f806";
}
.fi-rs-signal-alt-slash:before {
  content: "\f807";
}
.fi-rs-signal-alt:before {
  content: "\f808";
}
.fi-rs-signal-bars-fair:before {
  content: "\f809";
}
.fi-rs-signal-bars-good:before {
  content: "\f80a";
}
.fi-rs-signal-bars-weak:before {
  content: "\f80b";
}
.fi-rs-signal-stream-slash:before {
  content: "\f80c";
}
.fi-rs-signal-stream:before {
  content: "\f80d";
}
.fi-rs-signature-lock:before {
  content: "\f80e";
}
.fi-rs-signature-slash:before {
  content: "\f80f";
}
.fi-rs-signature:before {
  content: "\f810";
}
.fi-rs-sitemap:before {
  content: "\f811";
}
.fi-rs-skateboard:before {
  content: "\f812";
}
.fi-rs-skating:before {
  content: "\f813";
}
.fi-rs-skeleton:before {
  content: "\f814";
}
.fi-rs-skewer:before {
  content: "\f815";
}
.fi-rs-ski-jump:before {
  content: "\f816";
}
.fi-rs-ski-lift:before {
  content: "\f817";
}
.fi-rs-skiing-nordic:before {
  content: "\f818";
}
.fi-rs-skiing:before {
  content: "\f819";
}
.fi-rs-skull-crossbones:before {
  content: "\f81a";
}
.fi-rs-skull:before {
  content: "\f81b";
}
.fi-rs-slash:before {
  content: "\f81c";
}
.fi-rs-sledding:before {
  content: "\f81d";
}
.fi-rs-sleigh:before {
  content: "\f81e";
}
.fi-rs-sliders-h-square:before {
  content: "\f81f";
}
.fi-rs-sliders-v-square:before {
  content: "\f820";
}
.fi-rs-sliders-v:before {
  content: "\f821";
}
.fi-rs-smartphone:before {
  content: "\f822";
}
.fi-rs-smile-beam:before {
  content: "\f823";
}
.fi-rs-smile-plus:before {
  content: "\f824";
}
.fi-rs-smile-wink:before {
  content: "\f825";
}
.fi-rs-smiley-comment-alt:before {
  content: "\f826";
}
.fi-rs-smog:before {
  content: "\f827";
}
.fi-rs-smoke:before {
  content: "\f828";
}
.fi-rs-smoking-ban:before {
  content: "\f829";
}
.fi-rs-smoking:before {
  content: "\f82a";
}
.fi-rs-snooze:before {
  content: "\f82b";
}
.fi-rs-snow-blowing:before {
  content: "\f82c";
}
.fi-rs-snowboarding:before {
  content: "\f82d";
}
.fi-rs-snowflake:before {
  content: "\f82e";
}
.fi-rs-snowflakes:before {
  content: "\f82f";
}
.fi-rs-snowman-alt:before {
  content: "\f830";
}
.fi-rs-snowman-head:before {
  content: "\f831";
}
.fi-rs-snowmobile:before {
  content: "\f832";
}
.fi-rs-snowplow:before {
  content: "\f833";
}
.fi-rs-soap:before {
  content: "\f834";
}
.fi-rs-social-network:before {
  content: "\f835";
}
.fi-rs-solar-system:before {
  content: "\f836";
}
.fi-rs-sort-alpha-down-alt:before {
  content: "\f837";
}
.fi-rs-sort-alpha-down:before {
  content: "\f838";
}
.fi-rs-sort-alpha-up-alt:before {
  content: "\f839";
}
.fi-rs-sort-alpha-up:before {
  content: "\f83a";
}
.fi-rs-sort-alt:before {
  content: "\f83b";
}
.fi-rs-sort-amount-down-alt:before {
  content: "\f83c";
}
.fi-rs-sort-amount-down:before {
  content: "\f83d";
}
.fi-rs-sort-amount-up-alt:before {
  content: "\f83e";
}
.fi-rs-sort-amount-up:before {
  content: "\f83f";
}
.fi-rs-sort-down:before {
  content: "\f840";
}
.fi-rs-sort-numeric-down-alt:before {
  content: "\f841";
}
.fi-rs-sort-numeric-down:before {
  content: "\f842";
}
.fi-rs-sort-shapes-down:before {
  content: "\f843";
}
.fi-rs-sort-shapes-up:before {
  content: "\f844";
}
.fi-rs-sort-size-down:before {
  content: "\f845";
}
.fi-rs-sort-size-up:before {
  content: "\f846";
}
.fi-rs-sort:before {
  content: "\f847";
}
.fi-rs-soup:before {
  content: "\f848";
}
.fi-rs-spa:before {
  content: "\f849";
}
.fi-rs-space-shuttle:before {
  content: "\f84a";
}
.fi-rs-space-station-moon-alt:before {
  content: "\f84b";
}
.fi-rs-space-station-moon:before {
  content: "\f84c";
}
.fi-rs-spade:before {
  content: "\f84d";
}
.fi-rs-spaghetti-monster-flying:before {
  content: "\f84e";
}
.fi-rs-sparkles:before {
  content: "\f84f";
}
.fi-rs-speaker:before {
  content: "\f850";
}
.fi-rs-speakers:before {
  content: "\f851";
}
.fi-rs-sphere:before {
  content: "\f852";
}
.fi-rs-spider-web:before {
  content: "\f853";
}
.fi-rs-spinner:before {
  content: "\f854";
}
.fi-rs-split:before {
  content: "\f855";
}
.fi-rs-splotch:before {
  content: "\f856";
}
.fi-rs-spoon:before {
  content: "\f857";
}
.fi-rs-spray-can:before {
  content: "\f858";
}
.fi-rs-square-0:before {
  content: "\f859";
}
.fi-rs-square-1:before {
  content: "\f85a";
}
.fi-rs-square-2:before {
  content: "\f85b";
}
.fi-rs-square-3:before {
  content: "\f85c";
}
.fi-rs-square-4:before {
  content: "\f85d";
}
.fi-rs-square-5:before {
  content: "\f85e";
}
.fi-rs-square-6:before {
  content: "\f85f";
}
.fi-rs-square-7:before {
  content: "\f860";
}
.fi-rs-square-8:before {
  content: "\f861";
}
.fi-rs-square-9:before {
  content: "\f862";
}
.fi-rs-square-a:before {
  content: "\f863";
}
.fi-rs-square-b:before {
  content: "\f864";
}
.fi-rs-square-bolt:before {
  content: "\f865";
}
.fi-rs-square-c:before {
  content: "\f866";
}
.fi-rs-square-code:before {
  content: "\f867";
}
.fi-rs-square-d:before {
  content: "\f868";
}
.fi-rs-square-dashed:before {
  content: "\f869";
}
.fi-rs-square-e:before {
  content: "\f86a";
}
.fi-rs-square-ellipsis-vertical:before {
  content: "\f86b";
}
.fi-rs-square-ellipsis:before {
  content: "\f86c";
}
.fi-rs-square-exclamation:before {
  content: "\f86d";
}
.fi-rs-square-f:before {
  content: "\f86e";
}
.fi-rs-square-g:before {
  content: "\f86f";
}
.fi-rs-square-h:before {
  content: "\f870";
}
.fi-rs-square-heart:before {
  content: "\f871";
}
.fi-rs-square-i:before {
  content: "\f872";
}
.fi-rs-square-info:before {
  content: "\f873";
}
.fi-rs-square-j:before {
  content: "\f874";
}
.fi-rs-square-k:before {
  content: "\f875";
}
.fi-rs-square-kanban:before {
  content: "\f876";
}
.fi-rs-square-l:before {
  content: "\f877";
}
.fi-rs-square-m:before {
  content: "\f878";
}
.fi-rs-square-minus:before {
  content: "\f879";
}
.fi-rs-square-n:before {
  content: "\f87a";
}
.fi-rs-square-o:before {
  content: "\f87b";
}
.fi-rs-square-p:before {
  content: "\f87c";
}
.fi-rs-square-phone-hangup:before {
  content: "\f87d";
}
.fi-rs-square-plus:before {
  content: "\f87e";
}
.fi-rs-square-poll-horizontal:before {
  content: "\f87f";
}
.fi-rs-square-poll-vertical:before {
  content: "\f880";
}
.fi-rs-square-q:before {
  content: "\f881";
}
.fi-rs-square-quote:before {
  content: "\f882";
}
.fi-rs-square-r:before {
  content: "\f883";
}
.fi-rs-square-root:before {
  content: "\f884";
}
.fi-rs-square-s:before {
  content: "\f885";
}
.fi-rs-square-small:before {
  content: "\f886";
}
.fi-rs-square-star:before {
  content: "\f887";
}
.fi-rs-square-t:before {
  content: "\f888";
}
.fi-rs-square-terminal:before {
  content: "\f889";
}
.fi-rs-square-u:before {
  content: "\f88a";
}
.fi-rs-square-v:before {
  content: "\f88b";
}
.fi-rs-square-w:before {
  content: "\f88c";
}
.fi-rs-square-x:before {
  content: "\f88d";
}
.fi-rs-square-y:before {
  content: "\f88e";
}
.fi-rs-square-z:before {
  content: "\f88f";
}
.fi-rs-square:before {
  content: "\f890";
}
.fi-rs-squircle:before {
  content: "\f891";
}
.fi-rs-stamp:before {
  content: "\f892";
}
.fi-rs-standard-definition:before {
  content: "\f893";
}
.fi-rs-star-and-crescent:before {
  content: "\f894";
}
.fi-rs-star-christmas:before {
  content: "\f895";
}
.fi-rs-star-comment-alt:before {
  content: "\f896";
}
.fi-rs-star-exclamation:before {
  content: "\f897";
}
.fi-rs-star-octogram:before {
  content: "\f898";
}
.fi-rs-star-of-david:before {
  content: "\f899";
}
.fi-rs-star-sharp-half-stroke:before {
  content: "\f89a";
}
.fi-rs-star-sharp-half:before {
  content: "\f89b";
}
.fi-rs-star-shooting:before {
  content: "\f89c";
}
.fi-rs-star:before {
  content: "\f89d";
}
.fi-rs-starfighter:before {
  content: "\f89e";
}
.fi-rs-stars:before {
  content: "\f89f";
}
.fi-rs-stats:before {
  content: "\f8a0";
}
.fi-rs-steak:before {
  content: "\f8a1";
}
.fi-rs-steering-wheel:before {
  content: "\f8a2";
}
.fi-rs-step-backward:before {
  content: "\f8a3";
}
.fi-rs-step-forward:before {
  content: "\f8a4";
}
.fi-rs-sterling-sign:before {
  content: "\f8a5";
}
.fi-rs-stethoscope:before {
  content: "\f8a6";
}
.fi-rs-sticker:before {
  content: "\f8a7";
}
.fi-rs-stocking:before {
  content: "\f8a8";
}
.fi-rs-stomach:before {
  content: "\f8a9";
}
.fi-rs-stop-circle:before {
  content: "\f8aa";
}
.fi-rs-stop:before {
  content: "\f8ab";
}
.fi-rs-stopwatch:before {
  content: "\f8ac";
}
.fi-rs-store-alt:before {
  content: "\f8ad";
}
.fi-rs-store-lock:before {
  content: "\f8ae";
}
.fi-rs-store-slash:before {
  content: "\f8af";
}
.fi-rs-strawberry:before {
  content: "\f8b0";
}
.fi-rs-street-view:before {
  content: "\f8b1";
}
.fi-rs-stretcher:before {
  content: "\f8b2";
}
.fi-rs-strikethrough:before {
  content: "\f8b3";
}
.fi-rs-stroopwafel:before {
  content: "\f8b4";
}
.fi-rs-subscript:before {
  content: "\f8b5";
}
.fi-rs-subtitles:before {
  content: "\f8b6";
}
.fi-rs-subway:before {
  content: "\f8b7";
}
.fi-rs-suitcase-alt:before {
  content: "\f8b8";
}
.fi-rs-summer:before {
  content: "\f8b9";
}
.fi-rs-sun-dust:before {
  content: "\f8ba";
}
.fi-rs-sun:before {
  content: "\f8bb";
}
.fi-rs-sunrise-alt:before {
  content: "\f8bc";
}
.fi-rs-sunrise:before {
  content: "\f8bd";
}
.fi-rs-sunset:before {
  content: "\f8be";
}
.fi-rs-superscript:before {
  content: "\f8bf";
}
.fi-rs-surfing:before {
  content: "\f8c0";
}
.fi-rs-surprise:before {
  content: "\f8c1";
}
.fi-rs-sushi:before {
  content: "\f8c2";
}
.fi-rs-swatchbook:before {
  content: "\f8c3";
}
.fi-rs-swimmer:before {
  content: "\f8c4";
}
.fi-rs-sword:before {
  content: "\f8c5";
}
.fi-rs-symbol:before {
  content: "\f8c6";
}
.fi-rs-symbols:before {
  content: "\f8c7";
}
.fi-rs-syringe:before {
  content: "\f8c8";
}
.fi-rs-t:before {
  content: "\f8c9";
}
.fi-rs-table-columns:before {
  content: "\f8ca";
}
.fi-rs-table-layout:before {
  content: "\f8cb";
}
.fi-rs-table-list:before {
  content: "\f8cc";
}
.fi-rs-table-picnic:before {
  content: "\f8cd";
}
.fi-rs-table-pivot:before {
  content: "\f8ce";
}
.fi-rs-table-rows:before {
  content: "\f8cf";
}
.fi-rs-table-tree:before {
  content: "\f8d0";
}
.fi-rs-table:before {
  content: "\f8d1";
}
.fi-rs-tablet:before {
  content: "\f8d2";
}
.fi-rs-tachometer-alt-average:before {
  content: "\f8d3";
}
.fi-rs-tachometer-alt-fastest:before {
  content: "\f8d4";
}
.fi-rs-tachometer-alt-slow:before {
  content: "\f8d5";
}
.fi-rs-tachometer-alt-slowest:before {
  content: "\f8d6";
}
.fi-rs-tachometer-average:before {
  content: "\f8d7";
}
.fi-rs-tachometer-fast:before {
  content: "\f8d8";
}
.fi-rs-tachometer-fastest:before {
  content: "\f8d9";
}
.fi-rs-tachometer-slow:before {
  content: "\f8da";
}
.fi-rs-tachometer-slowest:before {
  content: "\f8db";
}
.fi-rs-tachometer:before {
  content: "\f8dc";
}
.fi-rs-taco:before {
  content: "\f8dd";
}
.fi-rs-tags:before {
  content: "\f8de";
}
.fi-rs-tally-1:before {
  content: "\f8df";
}
.fi-rs-tally-2:before {
  content: "\f8e0";
}
.fi-rs-tally-3:before {
  content: "\f8e1";
}
.fi-rs-tally-4:before {
  content: "\f8e2";
}
.fi-rs-tally:before {
  content: "\f8e3";
}
.fi-rs-target:before {
  content: "\f8e4";
}
.fi-rs-taxi-bus:before {
  content: "\f8e5";
}
.fi-rs-taxi:before {
  content: "\f8e6";
}
.fi-rs-teddy-bear:before {
  content: "\f8e7";
}
.fi-rs-teeth-open:before {
  content: "\f8e8";
}
.fi-rs-telescope:before {
  content: "\f8e9";
}
.fi-rs-temperature-down:before {
  content: "\f8ea";
}
.fi-rs-temperature-frigid:before {
  content: "\f8eb";
}
.fi-rs-temperature-high:before {
  content: "\f8ec";
}
.fi-rs-temperature-list:before {
  content: "\f8ed";
}
.fi-rs-temperature-low:before {
  content: "\f8ee";
}
.fi-rs-temperature-up:before {
  content: "\f8ef";
}
.fi-rs-template-alt:before {
  content: "\f8f0";
}
.fi-rs-template:before {
  content: "\f8f1";
}
.fi-rs-tenge:before {
  content: "\f8f2";
}
.fi-rs-tennis:before {
  content: "\f8f3";
}
.fi-rs-terminal:before {
  content: "\f8f4";
}
.fi-rs-terrace:before {
  content: "\f8f5";
}
.fi-rs-test-tube:before {
  content: "\f8f6";
}
.fi-rs-test:before {
  content: "\f8f7";
}
.fi-rs-text-check:before {
  content: "\f8f8";
}
.fi-rs-text-height:before {
  content: "\f8f9";
}
.fi-rs-text-shadow:before {
  content: "\f8fa";
}
.fi-rs-text-size:before {
  content: "\f8fb";
}
.fi-rs-text-slash:before {
  content: "\f8fc";
}
.fi-rs-text-width:before {
  content: "\f8fd";
}
.fi-rs-text:before {
  content: "\f8fe";
}
.fi-rs-theater-masks:before {
  content: "\f8ff";
}
.fi-rs-thermometer-empty:before {
  content: "\f900";
}
.fi-rs-thermometer-full:before {
  content: "\f901";
}
.fi-rs-thermometer-half:before {
  content: "\f902";
}
.fi-rs-thermometer-quarter:before {
  content: "\f903";
}
.fi-rs-thermometer-three-quarters:before {
  content: "\f904";
}
.fi-rs-theta:before {
  content: "\f905";
}
.fi-rs-thought-bubble:before {
  content: "\f906";
}
.fi-rs-thumbtack:before {
  content: "\f907";
}
.fi-rs-thunderstorm-moon:before {
  content: "\f908";
}
.fi-rs-thunderstorm-sun:before {
  content: "\f909";
}
.fi-rs-thunderstorm:before {
  content: "\f90a";
}
.fi-rs-ticket-airline:before {
  content: "\f90b";
}
.fi-rs-ticket-alt:before {
  content: "\f90c";
}
.fi-rs-ticket:before {
  content: "\f90d";
}
.fi-rs-tickets-airline:before {
  content: "\f90e";
}
.fi-rs-tilde:before {
  content: "\f90f";
}
.fi-rs-time-add:before {
  content: "\f910";
}
.fi-rs-time-check:before {
  content: "\f911";
}
.fi-rs-time-delete:before {
  content: "\f912";
}
.fi-rs-time-fast:before {
  content: "\f913";
}
.fi-rs-time-forward-sixty:before {
  content: "\f914";
}
.fi-rs-time-forward-ten:before {
  content: "\f915";
}
.fi-rs-time-forward:before {
  content: "\f916";
}
.fi-rs-time-half-past:before {
  content: "\f917";
}
.fi-rs-time-oclock:before {
  content: "\f918";
}
.fi-rs-time-past:before {
  content: "\f919";
}
.fi-rs-time-quarter-past:before {
  content: "\f91a";
}
.fi-rs-time-quarter-to:before {
  content: "\f91b";
}
.fi-rs-time-twenty-four:before {
  content: "\f91c";
}
.fi-rs-times-hexagon:before {
  content: "\f91d";
}
.fi-rs-tint-slash:before {
  content: "\f91e";
}
.fi-rs-tire-flat:before {
  content: "\f91f";
}
.fi-rs-tire-pressure-warning:before {
  content: "\f920";
}
.fi-rs-tire-rugged:before {
  content: "\f921";
}
.fi-rs-tire:before {
  content: "\f922";
}
.fi-rs-tired:before {
  content: "\f923";
}
.fi-rs-toggle-off:before {
  content: "\f924";
}
.fi-rs-toggle-on:before {
  content: "\f925";
}
.fi-rs-toilet-paper-blank:before {
  content: "\f926";
}
.fi-rs-tomato:before {
  content: "\f927";
}
.fi-rs-tombstone-alt:before {
  content: "\f928";
}
.fi-rs-tombstone:before {
  content: "\f929";
}
.fi-rs-tool-box:before {
  content: "\f92a";
}
.fi-rs-tool-crop:before {
  content: "\f92b";
}
.fi-rs-tool-marquee:before {
  content: "\f92c";
}
.fi-rs-tooth:before {
  content: "\f92d";
}
.fi-rs-toothbrush:before {
  content: "\f92e";
}
.fi-rs-tornado:before {
  content: "\f92f";
}
.fi-rs-tower-control:before {
  content: "\f930";
}
.fi-rs-tractor:before {
  content: "\f931";
}
.fi-rs-trademark:before {
  content: "\f932";
}
.fi-rs-traffic-light-go:before {
  content: "\f933";
}
.fi-rs-traffic-light-slow:before {
  content: "\f934";
}
.fi-rs-traffic-light-stop:before {
  content: "\f935";
}
.fi-rs-traffic-light:before {
  content: "\f936";
}
.fi-rs-trailer:before {
  content: "\f937";
}
.fi-rs-train-side:before {
  content: "\f938";
}
.fi-rs-train-subway-tunnel:before {
  content: "\f939";
}
.fi-rs-train-tram:before {
  content: "\f93a";
}
.fi-rs-train:before {
  content: "\f93b";
}
.fi-rs-tram:before {
  content: "\f93c";
}
.fi-rs-transform:before {
  content: "\f93d";
}
.fi-rs-transporter-1:before {
  content: "\f93e";
}
.fi-rs-transporter-2:before {
  content: "\f93f";
}
.fi-rs-transporter-3:before {
  content: "\f940";
}
.fi-rs-transporter-4:before {
  content: "\f941";
}
.fi-rs-transporter-5:before {
  content: "\f942";
}
.fi-rs-transporter-6:before {
  content: "\f943";
}
.fi-rs-transporter-7:before {
  content: "\f944";
}
.fi-rs-transporter-empty:before {
  content: "\f945";
}
.fi-rs-transporter:before {
  content: "\f946";
}
.fi-rs-trash-can-check:before {
  content: "\f947";
}
.fi-rs-trash-can-clock:before {
  content: "\f948";
}
.fi-rs-trash-can-list:before {
  content: "\f949";
}
.fi-rs-trash-can-plus:before {
  content: "\f94a";
}
.fi-rs-trash-can-slash:before {
  content: "\f94b";
}
.fi-rs-trash-check:before {
  content: "\f94c";
}
.fi-rs-trash-clock:before {
  content: "\f94d";
}
.fi-rs-trash-list:before {
  content: "\f94e";
}
.fi-rs-trash-plus:before {
  content: "\f94f";
}
.fi-rs-trash-restore-alt:before {
  content: "\f950";
}
.fi-rs-trash-restore:before {
  content: "\f951";
}
.fi-rs-trash-slash:before {
  content: "\f952";
}
.fi-rs-trash-undo-alt:before {
  content: "\f953";
}
.fi-rs-trash-undo:before {
  content: "\f954";
}
.fi-rs-trash-xmark:before {
  content: "\f955";
}
.fi-rs-trash:before {
  content: "\f956";
}
.fi-rs-treasure-chest:before {
  content: "\f957";
}
.fi-rs-treatment:before {
  content: "\f958";
}
.fi-rs-tree-christmas:before {
  content: "\f959";
}
.fi-rs-tree-deciduous:before {
  content: "\f95a";
}
.fi-rs-tree:before {
  content: "\f95b";
}
.fi-rs-trees:before {
  content: "\f95c";
}
.fi-rs-triangle-music:before {
  content: "\f95d";
}
.fi-rs-triangle-warning:before {
  content: "\f95e";
}
.fi-rs-triangle:before {
  content: "\f95f";
}
.fi-rs-trophy-star:before {
  content: "\f960";
}
.fi-rs-trophy:before {
  content: "\f961";
}
.fi-rs-truck-container:before {
  content: "\f962";
}
.fi-rs-truck-couch:before {
  content: "\f963";
}
.fi-rs-truck-loading:before {
  content: "\f964";
}
.fi-rs-truck-monster:before {
  content: "\f965";
}
.fi-rs-truck-moving:before {
  content: "\f966";
}
.fi-rs-truck-pickup:before {
  content: "\f967";
}
.fi-rs-truck-plow:before {
  content: "\f968";
}
.fi-rs-truck-ramp:before {
  content: "\f969";
}
.fi-rs-truck-side:before {
  content: "\f96a";
}
.fi-rs-truck-tow:before {
  content: "\f96b";
}
.fi-rs-trumpet:before {
  content: "\f96c";
}
.fi-rs-tty:before {
  content: "\f96d";
}
.fi-rs-tubes:before {
  content: "\f96e";
}
.fi-rs-tugrik-sign:before {
  content: "\f96f";
}
.fi-rs-turkey:before {
  content: "\f970";
}
.fi-rs-turntable:before {
  content: "\f971";
}
.fi-rs-tv-music:before {
  content: "\f972";
}
.fi-rs-typewriter:before {
  content: "\f973";
}
.fi-rs-u:before {
  content: "\f974";
}
.fi-rs-ufo-beam:before {
  content: "\f975";
}
.fi-rs-ufo:before {
  content: "\f976";
}
.fi-rs-umbrella-beach:before {
  content: "\f977";
}
.fi-rs-umbrella:before {
  content: "\f978";
}
.fi-rs-underline:before {
  content: "\f979";
}
.fi-rs-undo-alt:before {
  content: "\f97a";
}
.fi-rs-undo:before {
  content: "\f97b";
}
.fi-rs-uniform-martial-arts:before {
  content: "\f97c";
}
.fi-rs-universal-access:before {
  content: "\f97d";
}
.fi-rs-unlock:before {
  content: "\f97e";
}
.fi-rs-up-left:before {
  content: "\f97f";
}
.fi-rs-up-right:before {
  content: "\f980";
}
.fi-rs-up:before {
  content: "\f981";
}
.fi-rs-upload:before {
  content: "\f982";
}
.fi-rs-usb-pendrive:before {
  content: "\f983";
}
.fi-rs-usd-circle:before {
  content: "\f984";
}
.fi-rs-usd-square:before {
  content: "\f985";
}
.fi-rs-user-add:before {
  content: "\f986";
}
.fi-rs-user-alien:before {
  content: "\f987";
}
.fi-rs-user-astronaut:before {
  content: "\f988";
}
.fi-rs-user-crown:before {
  content: "\f989";
}
.fi-rs-user-gear:before {
  content: "\f98a";
}
.fi-rs-user-md-chat:before {
  content: "\f98b";
}
.fi-rs-user-md:before {
  content: "\f98c";
}
.fi-rs-user-nurse:before {
  content: "\f98d";
}
.fi-rs-user-pen:before {
  content: "\f98e";
}
.fi-rs-user-pilot-tie:before {
  content: "\f98f";
}
.fi-rs-user-pilot:before {
  content: "\f990";
}
.fi-rs-user-robot:before {
  content: "\f991";
}
.fi-rs-user-slash:before {
  content: "\f992";
}
.fi-rs-user-time:before {
  content: "\f993";
}
.fi-rs-user-visor:before {
  content: "\f994";
}
.fi-rs-user:before {
  content: "\f995";
}
.fi-rs-users-alt:before {
  content: "\f996";
}
.fi-rs-users-gear:before {
  content: "\f997";
}
.fi-rs-users-medical:before {
  content: "\f998";
}
.fi-rs-users:before {
  content: "\f999";
}
.fi-rs-utensils:before {
  content: "\f99a";
}
.fi-rs-v:before {
  content: "\f99b";
}
.fi-rs-value-absolute:before {
  content: "\f99c";
}
.fi-rs-vault:before {
  content: "\f99d";
}
.fi-rs-vector-alt:before {
  content: "\f99e";
}
.fi-rs-vector-circle:before {
  content: "\f99f";
}
.fi-rs-vector-polygon:before {
  content: "\f9a0";
}
.fi-rs-vector:before {
  content: "\f9a1";
}
.fi-rs-venus-double:before {
  content: "\f9a2";
}
.fi-rs-venus-mars:before {
  content: "\f9a3";
}
.fi-rs-venus:before {
  content: "\f9a4";
}
.fi-rs-vest-patches:before {
  content: "\f9a5";
}
.fi-rs-vest:before {
  content: "\f9a6";
}
.fi-rs-video-arrow-down-left:before {
  content: "\f9a7";
}
.fi-rs-video-arrow-up-right:before {
  content: "\f9a8";
}
.fi-rs-video-camera-alt:before {
  content: "\f9a9";
}
.fi-rs-video-camera:before {
  content: "\f9aa";
}
.fi-rs-video-plus:before {
  content: "\f9ab";
}
.fi-rs-video-slash:before {
  content: "\f9ac";
}
.fi-rs-virus-slash:before {
  content: "\f9ad";
}
.fi-rs-virus:before {
  content: "\f9ae";
}
.fi-rs-viruses:before {
  content: "\f9af";
}
.fi-rs-voicemail:before {
  content: "\f9b0";
}
.fi-rs-volcano:before {
  content: "\f9b1";
}
.fi-rs-volleyball:before {
  content: "\f9b2";
}
.fi-rs-volume-down:before {
  content: "\f9b3";
}
.fi-rs-volume-mute:before {
  content: "\f9b4";
}
.fi-rs-volume-off:before {
  content: "\f9b5";
}
.fi-rs-volume-slash:before {
  content: "\f9b6";
}
.fi-rs-volume:before {
  content: "\f9b7";
}
.fi-rs-vote-nay:before {
  content: "\f9b8";
}
.fi-rs-vote-yea:before {
  content: "\f9b9";
}
.fi-rs-w:before {
  content: "\f9ba";
}
.fi-rs-wagon-covered:before {
  content: "\f9bb";
}
.fi-rs-walker:before {
  content: "\f9bc";
}
.fi-rs-walkie-talkie:before {
  content: "\f9bd";
}
.fi-rs-wallet:before {
  content: "\f9be";
}
.fi-rs-warehouse-alt:before {
  content: "\f9bf";
}
.fi-rs-watch-calculator:before {
  content: "\f9c0";
}
.fi-rs-watch-smart:before {
  content: "\f9c1";
}
.fi-rs-watch:before {
  content: "\f9c2";
}
.fi-rs-water-bottle:before {
  content: "\f9c3";
}
.fi-rs-water-ladder:before {
  content: "\f9c4";
}
.fi-rs-water-lower:before {
  content: "\f9c5";
}
.fi-rs-water-rise:before {
  content: "\f9c6";
}
.fi-rs-water:before {
  content: "\f9c7";
}
.fi-rs-watermelon:before {
  content: "\f9c8";
}
.fi-rs-wave-sine:before {
  content: "\f9c9";
}
.fi-rs-wave-square:before {
  content: "\f9ca";
}
.fi-rs-wave-triangle:before {
  content: "\f9cb";
}
.fi-rs-waveform-path:before {
  content: "\f9cc";
}
.fi-rs-waveform:before {
  content: "\f9cd";
}
.fi-rs-webcam-slash:before {
  content: "\f9ce";
}
.fi-rs-webcam:before {
  content: "\f9cf";
}
.fi-rs-wheat:before {
  content: "\f9d0";
}
.fi-rs-wheelchair-move:before {
  content: "\f9d1";
}
.fi-rs-wheelchair:before {
  content: "\f9d2";
}
.fi-rs-whistle:before {
  content: "\f9d3";
}
.fi-rs-wifi-1:before {
  content: "\f9d4";
}
.fi-rs-wifi-2:before {
  content: "\f9d5";
}
.fi-rs-wifi-alt:before {
  content: "\f9d6";
}
.fi-rs-wifi-exclamation:before {
  content: "\f9d7";
}
.fi-rs-wifi-slash:before {
  content: "\f9d8";
}
.fi-rs-wifi:before {
  content: "\f9d9";
}
.fi-rs-wind-warning:before {
  content: "\f9da";
}
.fi-rs-wind:before {
  content: "\f9db";
}
.fi-rs-window-alt:before {
  content: "\f9dc";
}
.fi-rs-window-maximize:before {
  content: "\f9dd";
}
.fi-rs-window-minimize:before {
  content: "\f9de";
}
.fi-rs-window-restore:before {
  content: "\f9df";
}
.fi-rs-windsock:before {
  content: "\f9e0";
}
.fi-rs-wine-bottle:before {
  content: "\f9e1";
}
.fi-rs-wine-glass-crack:before {
  content: "\f9e2";
}
.fi-rs-woman-head:before {
  content: "\f9e3";
}
.fi-rs-won-sign:before {
  content: "\f9e4";
}
.fi-rs-world:before {
  content: "\f9e5";
}
.fi-rs-wreath:before {
  content: "\f9e6";
}
.fi-rs-wrench-simple:before {
  content: "\f9e7";
}
.fi-rs-x:before {
  content: "\f9e8";
}
.fi-rs-y:before {
  content: "\f9e9";
}
.fi-rs-yen:before {
  content: "\f9ea";
}
.fi-rs-yin-yang:before {
  content: "\f9eb";
}
.fi-rs-z:before {
  content: "\f9ec";
}
.fi-rs-zoom-in:before {
  content: "\f9ed";
}
.fi-rs-zoom-out:before {
  content: "\f9ee";
}
